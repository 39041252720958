/* eslint-disable no-nested-ternary */
import React from "react";

import { useDispatch, useSelector } from "react-redux";

import { FormControlLabel, Switch } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { EventCode, Notification } from "@elx-element/common/enums";
import { getConfigurationBoolValue } from "@elx-element/common/envconf";
import { dispatchEvent, dispatchNotificationEvent } from "@elx-element/common/events/dispatchers";
import { NfcWriterControl, NotificationEventModel } from "@elx-element/common/events/types";
import { format } from "@elx-element/common/utils";
import BaseIcon from "@elx-element/ui/DataDisplay/BaseIcon";

import { mdiClose } from "@mdi/js";

import { selectCulture, selectNfcWriterSettings } from "../../store/main/selectors";
import { configureNfcWriter } from "../../store/main/slice";

import useTexts from "../../hooks/useTexts";

import useStyles from "./styles";

const debug = getConfigurationBoolValue(window.env.webcontainer, "ENABLE_DEBUG_LOG");

/** Type for the possible steps of the app */
type TStep =
  | "initializing"
  | "noNfc"
  | "nfcNotEnabled"
  | "waitingForNfcEnabled"
  | "waitingForTag"
  | "tagWrited"
  | "tagNotWrited";

const NfcWriter = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const texts = useTexts();
  const culture = useSelector(selectCulture);
  const nfcWriterSettings = useSelector(selectNfcWriterSettings);
  const [step, setStep] = React.useState<TStep>("initializing");

  // Zrušení zápisu NFC - zavření okna
  const handleClose = () => {
    if (nfc && typeof nfc !== "undefined") {
      // Unregister the event listener
      nfc.removeNdefListener(onNfcWrite);
    }

    // zavření okna
    dispatchEvent(EventCode.nfcWriterControlEvent, new NfcWriterControl(), debug);
  };

  const initializeNfc = () => {
    // If nfc is undefined, NFC is not available on this device, or
    // the app is running in a web browser
    if (nfc && typeof nfc !== "undefined") {
      // Register an event listener
      nfc.addNdefListener(
        onNfcWrite, // The callback function for the event listener
        () => setStep("waitingForTag"), // Success → We're waiting for an event
        () => setStep("nfcNotEnabled") // Error → NFC must not be enabled
      );
    } else {
      setStep("noNfc");
    }
  };

  // Zápis dat na NFC štítek, permanentní uzamčení pro další zápis.
  const onNfcWrite = (nfcEvent: any) => {
    if (typeof nfc !== "undefined") {
      // Unregister the event listener
      nfc.removeNdefListener(onNfcWrite);
    }

    // zápis dat
    const message = [ndef.textRecord(nfcWriterSettings!.data, culture, nfcEvent.tag.id)];
    if (nfc && typeof nfc !== "undefined") {
      nfc.write(message, onNfcWriteSuccess, onNfcWriteFailure);
      if (nfcWriterSettings!.makeReadOnly === true) {
        nfc.makeReadOnly(onNfcLockSuccess, onNfcLockFailure);
      }
    }
  };

  const onNfcLockSuccess = () => {
    dispatchNotificationEvent(new NotificationEventModel("NFC", Notification.success, texts.NFC_LOCK_SUCCESS));
  };

  const onNfcLockFailure = () => {
    dispatchNotificationEvent(new NotificationEventModel("NFC", Notification.error, texts.NFC_LOCK_FAILURE));
  };

  const onNfcWriteSuccess = () => {
    dispatchNotificationEvent(
      new NotificationEventModel(`NFC ${texts.WRITING}`, Notification.success, texts.NFC_DATA_WRITE_SUCCESS)
    );

    dispatchEvent(EventCode.nfcWriterControlEvent, new NfcWriterControl(), debug);
  };

  const onNfcWriteFailure = () => {
    dispatchNotificationEvent(
      new NotificationEventModel(`NFC ${texts.WRITING}`, Notification.error, texts.NFC_DATA_WRITE_ERROR)
    );

    dispatchEvent(EventCode.nfcWriterControlEvent, new NfcWriterControl(), debug);
  };

  // Otevření nastavení pro aktivaci NFC na mobilním zařízení
  const onGoToSettingsClick = () => {
    if (nfc && typeof nfc !== "undefined") {
      // Ask the device to open the NFC settings for the user
      nfc.showSettings(() => setStep("waitingForNfcEnabled"), undefined);
    }
  };

  // Změna nastavení uzamčení NFC tagu
  const onLockChange = (checked: boolean) => {
    if (nfcWriterSettings) {
      nfcWriterSettings.makeReadOnly = checked;
      dispatch(configureNfcWriter(nfcWriterSettings));
    }
  };

  // Init NFC při otevření writeru
  React.useEffect(() => {
    if (nfcWriterSettings?.active ?? false) {
      initializeNfc();
    }
  }, [nfcWriterSettings]);

  return (
    <Dialog
      fullScreen
      open={(nfcWriterSettings && nfcWriterSettings.active) ?? false}
      onClose={handleClose}
      data-app={process.env.REACT_APP_NAME}
      className={classes.nfcDialogPaper}
      classes={{
        container: classes.scannerDialogContainer,
      }}
    >
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <BaseIcon data={mdiClose} />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            <>
              NFC {texts.WRITING} {nfcWriterSettings?.name ? format("[{0}]", nfcWriterSettings?.name) : ""}
            </>
          </Typography>
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                value={nfcWriterSettings?.makeReadOnly}
                onChange={(e, checked) => onLockChange(checked)}
                disabled
              />
            }
            label={<b className={classes.title}>{texts.NFC_PERMANENT_LOCK_TAG}</b>}
            labelPlacement="start"
          />
        </Toolbar>
      </AppBar>

      {nfcWriterSettings?.makeReadOnly && <div className={classes.nfcLockWarning}>{texts.NFC_LOCK_WARNING}</div>}

      <div>
        {step === "initializing" ? (
          <div className={classes.nfcInfo}>{texts.NFC_INITIALIZING}</div>
        ) : step === "noNfc" ? (
          <div className={classes.nfcInfo}>{texts.NFC_NO_NFC}</div>
        ) : step === "nfcNotEnabled" ? (
          <div className={classes.nfcInfo}>{texts.NFC_NOT_ENABLED}</div>
        ) : step === "waitingForNfcEnabled" ? (
          <div className={classes.nfcInfo}>{texts.NFC_WAITING_FOR_ENABLED}</div>
        ) : (
          <div className={classes.nfcInfo}>{texts.NFC_WAITING_FOR_TAG}</div>
        )}
      </div>

      {step === "nfcNotEnabled" && (
        <div className={classes.scanButtonContainer}>
          <Button color="primary" variant="contained" className={classes.scanButton} onClick={onGoToSettingsClick}>
            {texts.NFC_GO_TO_NFC}
          </Button>
        </div>
      )}

      {step === "waitingForNfcEnabled" && (
        <div className={classes.scanButtonContainer}>
          <Button color="primary" variant="contained" className={classes.scanButton} onClick={initializeNfc}>
            {texts.NFC_INIT_NFC}
          </Button>
        </div>
      )}
    </Dialog>
  );
};

export default NfcWriter;
