import { makeStyles } from "tss-react/mui";

import { getConfigurationStringValue } from "@elx-element/common/envconf";

export const customThemeConfig = {
  logo: getConfigurationStringValue(window.env.webcontainer, "LOGO_URL"),
};

const drawerWidth = 240;
const drawerClosedWidth = 58;

type NavigationClassKey =
  | "navLink"
  | "menuListTitle"
  | "navLinkActive"
  | "scrolledList"
  | "tooltipPopper"
  | "notAllowed"
  | "mainNavButton"
  | "mainNavButtonContainer"
  | "mainNavButtonContainerFlexEnd"
  | "drawerPaper"
  | "drawerPaperClose"
  | "drawerPaperMobilePlatform"
  | "drawerPaperCloseMobilePlatform";

const useNavigationStyles = makeStyles<void, NavigationClassKey>({ name: "selectorName" })((theme, _, classes) => ({
  navLink: {
    textDecoration: "none",
    color: "inherit",
    "& svg": {
      fontSize: "x-large",
    },
    "& :hover svg": {
      opacity: 0.75,
    },
  },
  menuListTitle: {
    paddingLeft: theme.spacing(2.5),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(0.5),
  },
  navLinkActive: {
    borderRight: `2px solid ${theme.palette.primary.main}`,
    display: "block",
  },
  scrolledList: {
    padding: 0,
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: 0,
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: 0,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 0,
    },
  },
  tooltipPopper: {
    margin: `0 ${theme.spacing(1)}px`,
  },
  notAllowed: {
    "& div": {
      cursor: "not-allowed",
    },
  },
  mainNavButton: {
    color: theme.palette.primary.contrastText,
    marginLeft: theme.spacing(0.5),
  },
  mainNavButtonContainer: {
    display: "flex",
    backgroundColor: window.top!.env.themeV5.logoBackgroundColor ?? theme.palette.primary.main,
    backgroundImage: `url(${customThemeConfig.logo})`,
    backgroundPosition: "left",
    backgroundRepeat: "no-repeat",
  },
  mainNavButtonContainerFlexEnd: {
    justifyContent: "flex-end",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: "none",
    overflow: "hidden",
    border: "none",
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: "none",
    width: drawerClosedWidth,
    [`.${classes.mainNavButtonContainer}`]: {
      backgroundImage: "none",
    },
  },
  drawerPaperMobilePlatform: {
    position: "relative",
    whiteSpace: "nowrap",
    overflowX: "hidden",
    border: "none",
  },
  drawerPaperCloseMobilePlatform: {
    overflowX: "hidden",
  },
  infoBtn: {
    left: theme.spacing(0.5),
    [theme.breakpoints.down("md")]: {
      display: "block",
      textAlign: "left",
      "& p, svg": {
        display: "block",
        float: "left",
      },
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: theme.spacing(1),
      backgroundColor: "#EEF5FA",
      padding: 4,
      marginLeft: theme.spacing(1),
      "& p": {
        display: "none",
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
  },
}));
export default useNavigationStyles;
