/* eslint-disable no-nested-ternary */
import React from "react";

import { useDispatch, useSelector } from "react-redux";

import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { EventCode, Notification, ScanType } from "@elx-element/common/enums";
import { getConfigurationBoolValue } from "@elx-element/common/envconf";
import { dispatchNotificationEvent } from "@elx-element/common/events/dispatchers";
import { dispatchScannerEvent } from "@elx-element/common/events/scanner";
import { IScan, NotificationEventModel } from "@elx-element/common/events/types";
import { getLastGpsLocationToString } from "@elx-element/common/storage";
import BaseIcon from "@elx-element/ui/DataDisplay/BaseIcon";

import { mdiClose } from "@mdi/js";

import { selectScannerLastScan, selectScannerSettings } from "../../store/main/selectors";
import { setScannerLastScan } from "../../store/main/slice";

import useTexts from "../../hooks/useTexts";

import useStyles from "./styles";

const debug = getConfigurationBoolValue(window.env.webcontainer, "ENABLE_DEBUG_LOG");

/** Type for the possible steps of the app */
type TStep =
  | "initializing"
  | "noNfc"
  | "nfcNotEnabled"
  | "waitingForNfcEnabled"
  | "waitingForTag"
  | "tagRead"
  | "tagReadEmpty";

const NfcScanner = () => {
  const dispatch = useDispatch();
  const { classes, cx } = useStyles();
  const texts = useTexts();
  const scannerSettings = useSelector(selectScannerSettings);
  const scannerLastScan = useSelector(selectScannerLastScan);
  const [step, setStep] = React.useState<TStep>("initializing");

  // Zrušení skenování - zavření skeneru
  const handleClose = () => {
    if (typeof nfc !== "undefined") {
      // Unregister the event listener
      nfc.removeNdefListener(onNdefEvent);
    }
    // vyvolání události zrušení skenování - předání informace o zrušení cílovému modulu
    dispatchScannerEvent({ type: EventCode.scanAborted, scan: undefined }, debug);
  };

  // Kliknutí na tlačítko "POUŽÍT"
  const handleUseCode = () => {
    // Vyvolání eventu s naskenovanou hodnotou - předání hodnoty cílovému modullu
    dispatchScannerEvent({ type: EventCode.scanFinished, scan: scannerLastScan }, debug);
  };

  // Při opakovaném skenování vymaže poslední sken v paměti
  const handleScanAgain = () => {
    dispatch(setScannerLastScan(undefined));
    initializeNfc();
  };

  const initializeNfc = () => {
    // If nfc is undefined, NFC is not available on this device, or
    // the app is running in a web browser
    if (typeof nfc !== "undefined") {
      // Register an event listener
      nfc.addNdefListener(
        onNdefEvent, // The callback function for the event listener
        () => setStep("waitingForTag"), // Success → We're waiting for an event
        () => setStep("nfcNotEnabled") // Error → NFC must not be enabled
      );
      // Register an event listener for formatable tags
      nfc.addNdefFormatableListener(
        onNdefEvent, // The callback function for the event listener
        () => setStep("waitingForTag"), // Success → We're waiting for an event
        () => setStep("nfcNotEnabled") // Error → NFC must not be enabled
      );
    } else {
      setStep("noNfc");
    }
  };

  const onGoToSettingsClick = () => {
    if (typeof nfc !== "undefined") {
      // Ask the device to open the NFC settings for the user
      nfc.showSettings(() => setStep("waitingForNfcEnabled"), undefined);
    }
  };

  const onNdefEvent = (e: PhoneGapNfc.TagEvent) => {
    if (debug) {
      console.debug("TagEvent", e);
    }

    if (typeof nfc !== "undefined") {
      // Unregister the event listener
      nfc.removeNdefListener(onNdefEvent);
    }

    if ((e as PhoneGapNfc.NdefTagEvent).tag.ndefMessage) {
      // Retrieve the payload of the tag and decode it
      // https://www.oreilly.com/library/view/beginning-nfc/9781449324094/ch04.html
      const scannedValue = ndef.textHelper.decodePayload((e as PhoneGapNfc.NdefTagEvent).tag.ndefMessage[0].payload);

      if (debug) {
        console.debug("NdefMessage payload", scannedValue);
      }

      const scan: IScan = {
        type: ScanType.nfcMessage,
        value: scannedValue,
        gps: getLastGpsLocationToString(),
        name: scannerSettings?.name ?? "",
      };

      // Vyvolání eventu s naskenovanou hodnotou - předání hodnoty cílovému modulu.
      dispatchScannerEvent({ type: EventCode.scanPerformed, scan }, debug);
      // Uložení načtené hodnoty
      dispatch(setScannerLastScan(scan));
      setStep("tagRead");
    } else {
      dispatchNotificationEvent(new NotificationEventModel(texts.NFC_NO_DATA, Notification.info), debug);
      handleClose();
    }
  };

  // Init NFC při otevření skeneru
  React.useEffect(() => {
    if ((scannerSettings?.active ?? false) && scannerSettings?.type === ScanType.nfcMessage) {
      initializeNfc();
    }
  }, [scannerSettings]);

  return (
    <Dialog
      fullScreen
      open={(scannerSettings?.active ?? false) && scannerSettings?.type === ScanType.nfcMessage}
      onClose={handleClose}
      data-app={process.env.REACT_APP_NAME}
      className={classes.nfcDialogPaper}
      classes={{
        container: classes.scannerDialogContainer,
      }}
    >
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <BaseIcon data={mdiClose} />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            <>
              NFC {texts.SCANNING} {(scannerSettings?.name ?? "").toUpperCase()}
            </>
          </Typography>
        </Toolbar>
      </AppBar>

      <div className="nfc">
        {step === "initializing" ? (
          <div className={classes.nfcInfo}>{texts.NFC_INITIALIZING}</div>
        ) : step === "noNfc" ? (
          <div className={classes.nfcInfo}>{texts.NFC_NO_NFC}</div>
        ) : step === "nfcNotEnabled" ? (
          <div className={classes.nfcInfo}>{texts.NFC_NOT_ENABLED}</div>
        ) : step === "waitingForNfcEnabled" ? (
          <div className={classes.nfcInfo}>{texts.NFC_WAITING_FOR_ENABLED}</div>
        ) : step === "waitingForTag" ? (
          <div className={classes.nfcInfo}>{texts.NFC_WAITING_FOR_TAG}</div>
        ) : step === "tagRead" ? (
          <div className={classes.nfcInfo}>{scannerLastScan?.value}</div>
        ) : (
          <div className={classes.nfcInfo}>{texts.NFC_NO_DATA}</div>
        )}
      </div>

      {scannerLastScan && (step === "tagRead" || step === "tagReadEmpty") && (
        <div className={classes.scanButtonContainer}>
          {step === "tagRead" && (
            <Button color="primary" variant="contained" className={classes.scanButton} onClick={handleUseCode}>
              {texts.USE}
            </Button>
          )}
          <Button
            color="primary"
            variant="outlined"
            className={cx(classes.scanButton, classes.scanButtonAgain)}
            onClick={handleScanAgain}
          >
            {texts.SCAN_AGAIN}
          </Button>
        </div>
      )}

      {step === "nfcNotEnabled" && (
        <div className={classes.scanButtonContainer}>
          <Button color="primary" variant="contained" className={classes.scanButton} onClick={onGoToSettingsClick}>
            {texts.NFC_GO_TO_NFC}
          </Button>
        </div>
      )}

      {step === "waitingForNfcEnabled" && (
        <div className={classes.scanButtonContainer}>
          <Button color="primary" variant="contained" className={classes.scanButton} onClick={initializeNfc}>
            {texts.NFC_INIT_NFC}
          </Button>
        </div>
      )}
    </Dialog>
  );
};

export default NfcScanner;
