import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  errorBox: {
    zIndex: theme.zIndex.modal + 1,
  },
  errorTitle: {
    padding: theme.spacing(2),
  },
  errorContent: {
    padding: theme.spacing(2),
    wordBreak: "break-word",
  },
  errorFooter: {
    padding: `${theme.spacing(2)}`,
  },
  closeButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  confluence: {
    "& .confluence-information-macro-information": {
      backgroundColor: "#DEEBFF",
      padding: 8,
      borderRadius: 3,
      marginTop: "0.75rem",
    },
    "& .confluence-information-macro-note": {
      backgroundColor: "#FFFAE6",
      padding: 8,
      borderRadius: 3,
      marginTop: "0.75rem",
    },
    "& .confluence-information-macro-warning": {
      backgroundColor: "#FFEBE6",
      padding: 8,
      borderRadius: 3,
      marginTop: "0.75rem",
    },
    "& .confluence-information-macro-tip": {
      backgroundColor: "#E3FCEF",
      padding: 8,
      borderRadius: 3,
      marginTop: "0.75rem",
    },
  },
}));
export default useStyles;
