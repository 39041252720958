/* eslint-disable import/prefer-default-export */
import { useEffect } from "react";

import { ElementInformationType } from "@elx-element/common/enums";
import { registerElementInformationListener } from "@elx-element/common/events/listeners";
import { setElementInformationStorage } from "@elx-element/common/storage";
import { RegisteredModule } from "@elx-element/common/types";

import store from "../store";

export const useElementInformations = (enabled: boolean) => {
  // Řešení požadavků na poskytnutí sdílených informací o element prostředí mezi modulem a webcontainerem.
  const handleElementInformationRequest = (
    informationType: ElementInformationType,
    registeredModules: Array<RegisteredModule>
  ) => {
    // eslint-disable-next-line default-case
    switch (informationType) {
      case ElementInformationType.modules:
        setElementInformationStorage(JSON.stringify(registeredModules));
        break;
      default:
        setElementInformationStorage("");
        break;
    }
  };

  useEffect(() => {
    if (enabled) {
      // Listener pro požadavky na poskytnutí sdílených informací o element prostředí mezi modulem a webcontainerem.
      registerElementInformationListener(event =>
        handleElementInformationRequest(event.detail, store.getState().main.registeredModules)
      );
    }
  }, [enabled]);
};
