import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  paper: {
    border: "none",
    maxWidth: 1024,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "100%",
      margin: 0,
      position: "absolute",
      top: 0,
      maxHeight: "100%",
      height: "100%",
      borderRadius: 0,
    },
  },
  root: {
    width: 1000,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
  },
  content: {
    minHeight: 500,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      borderLeft: "none",
      paddingLeft: 0,
      padding: theme.spacing(2),
    },
  },
  halfCol: {
    width: "50%",
    marginTop: theme.spacing(5),
    float: "left",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  hr: {
    clear: "both",
    border: "none",
  },
  closeBtn: {
    zIndex: theme.zIndex.modal + 1,
    float: "right",
    marginBottom: theme.spacing(-6),
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      right: 0,
      color: theme.palette.primary.contrastText,
    },
  },
}));

export default useStyles;
