/* eslint-disable import/prefer-default-export */
import { useEffect } from "react";

import { EventCode, Notification } from "@elx-element/common/enums";
import { dispatchNotificationEvent } from "@elx-element/common/events/dispatchers";
import { registerWhispererRequestedListener } from "@elx-element/common/events/listeners";
import { NotificationEventModel, WhispererRequest, WhispererResponse } from "@elx-element/common/events/types";
import { RegisteredModule } from "@elx-element/common/types";
import { format } from "@elx-element/common/utils";

import store from "../store";

import useTexts from "./useTexts";

/**
 * Obsluha událostí požadavku našeptávání informací.
 * @param enabled
 */
export const useWhispererEvents = (enabled: boolean) => {
  const texts = useTexts();

  /**
   * Hlídání komunikace našeptávače. Pokud je volán neexistující našeptávač, vyrobíme notifikaci s upozoprněním že našeptávač neexistuje.
   * @param eventDetail
   * @param registeredModules
   */
  const handleWhispererRequest = (
    whispererRequest: WhispererRequest | undefined,
    registeredModules: Array<RegisteredModule>
  ) => {
    if (whispererRequest) {
      if (registeredModules.find(x => x.moduleId === whispererRequest.moduleName && x.interface?.services?.whisperer)) {
        // našeptávač existuje
      } else {
        dispatchNotificationEvent(
          new NotificationEventModel(
            format(texts.WHISPERER_ISNOT_ACTIVE, whispererRequest.moduleName),
            Notification.warning
          )
        );
        // naslouchájícímu modulu vrátíme prázdná data
        window.dispatchEvent(
          new CustomEvent(`${EventCode.whispererCompleted}:${whispererRequest.moduleName}`, {
            detail: { totalCount: 0, items: [] } as WhispererResponse,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (enabled) {
      // Listener komunikace volání našeptávače (detekuje volání neexistujícího whispereru).
      registerWhispererRequestedListener(event =>
        handleWhispererRequest(event.detail, store.getState().main.registeredModules)
      );
    }
  }, [enabled]);
};
