import { WebContainerPlatform } from "@elx-element/common/enums";

import { AppState } from "..";

export const selectCulture = (state: AppState) => state.main.culture;
export const selectCultures = (state: AppState) => state.main.cultures;
export const selectRoutes = (state: AppState) => state.main.routes;
export const selectNetworkStatus = (state: AppState) => state.main.networkStatus;
export const selectNetworkType = (state: AppState) => state.main.networkType;
export const selectNetworkStrength = (state: AppState) => state.main.networkStrength;
export const selectMachineSettings = (state: AppState) => state.main.machineSettings;
export const selectInfoModalIsOpen = (state: AppState) => state.main.infoPopupOpen;
export const selectMainMenuOpen = (state: AppState) => state.main.mainMenuOpen;
export const selectActiveModuleId = (state: AppState) => state.main.activeModuleId;
export const selectPlatformIsActive = (state: AppState) => state.main.platformIsActive;
export const selectScannerSettings = (state: AppState) => state.main.scannerSettings;
export const selectScannerLastScan = (state: AppState) => state.main.scannerLastScan;
export const selectNfcWriterSettings = (state: AppState) => state.main.nfcWriterSettings;
export const selectActiveModule = (state: AppState) =>
  state.main.registeredModules.find(x => x.moduleId === state.main.activeModuleId);
export const selectRegisteredModules = (state: AppState) => state.main.registeredModules;
export const selectRegisteredModulesLoaded = (state: AppState) => state.main.registeredModulesLoaded;
export const selectPlatform = (state: AppState) => state.main.platform;
export const isMobilePlatform = (state: AppState) => state.main.platform !== WebContainerPlatform.web;
export const selectDashboardSettings = (state: AppState) => state.main.dashboardSettings;
export const selectOpenErrorDetailReference = (state: AppState) => state.main.openErrorDetailReference;
export const selectModuleUpdated = (state: AppState) => state.main.moduleUpdated;
export const selectWebContainerUpdated = (state: AppState) => state.main.webContainerUpdated;
export const selectAudioBuffer = (state: AppState) => state.main.audioBuffer;
