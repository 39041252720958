/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import { registerPlugin, WebPlugin } from '@capacitor/core';

import {
  DataWedgweOptions,
  DecoderOptions,
  ScannerOptions,
  SimplifiedDecoderOptions,
  ZebraScannerPlugin,
} from './definitions';

export class ZebraScannerWeb extends WebPlugin implements ZebraScannerPlugin {
  constructor() {
    super({
      name: 'ZebraScanner',
      platforms: ['web'],
    });
  }

  setDataWedgeOptions(_options: DataWedgweOptions): Promise<{}> {
    throw new Error(
      '[capacitor-elinkx-zebra-scanner]: Method not implemented.',
    );
  }

  async start(_options: ScannerOptions): Promise<{}> {
    throw new Error(
      '[capacitor-elinkx-zebra-scanner]: start not supported under the web platform',
    );
  }

  async stop(): Promise<any> {
    throw new Error(
      '[capacitor-elinkx-zebra-scanner]: stop not supported under the web platform',
    );
  }

  async echo(_options: { value: string }): Promise<{ value: string }> {
    throw new Error(
      '[capacitor-elinkx-zebra-scanner]: echo not supported under the web platform',
    );
  }

  async setDecoders(_decoders: DecoderOptions): Promise<{}> {
    throw new Error(
      '[capacitor-elinkx-zebra-scanner]: setDecoders not supported under the web platform',
    );
  }

  async setDecodersFromConfigString(
    _options: SimplifiedDecoderOptions,
  ): Promise<{}> {
    throw new Error(
      '[capacitor-elinkx-zebra-scanner]: setDecodersFromConfigString not supported under the web platform',
    );
  }

  async createProfile(): Promise<{}> {
    throw new Error(
      '[capacitor-elinkx-zebra-scanner]: createProfile not supported under the web platform',
    );
  }

  async toggleSoftScanTrigger(): Promise<{}> {
    throw new Error(
      '[capacitor-elinkx-zebra-scanner]: toggleSoftScanTrigger not supported under the web platform',
    );
  }
}

const ZebraScanner = registerPlugin<ZebraScannerWeb>('ZebraScanner');

export { ZebraScanner };
