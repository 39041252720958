import { makeStyles } from "tss-react/mui";

import { formControlLabelClasses } from "@mui/material/FormControlLabel";
import { switchClasses } from "@mui/material/Switch";

const useStyles = makeStyles()(theme => ({
  formControlLabel: {
    [`& .${switchClasses.root}`]: {
      marginTop: 0,
    },
    [`& .${formControlLabelClasses.label}`]: {
      marginTop: 4,
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
}));

export default useStyles;
