import Lang from "./lang";

const enUS: Lang = {
  DASHBOARD: "Dashboard",
  COPYRIGHT_TEXT: "E LINKX a.s.",
  COPYRIGHT_LINK: "https://www.elinkx.cz",
  ACTIVE_MODULES: "Installed modules",
  VERSION: "Version",
  BUILD: "Build",
  SETTINGS: "Settings",
  LOGOUT: "Logout",
  INCOMPATIBILITY: "This module may be incompatible.",
  INCOMPATIBILITY_NOTIFICATION: "Warning! Some modules may be incompatible.",
  UNSUPPORTED_COMMUNICATION_TYPE: "Unsupported communication type.",
  UNSUPPORTED_ACTION_DATA: "Action data error.",
  DATETIME: "Date & time",
  TYPE: "Type",
  SPECIFICATION: "Specification",
  RANK: "Order",
  BACK_TO_DASHBOARD: "Back to DASHBOARD",
  NOTIFICATION: "Notifications",
  MESSAGES: "Messages",
  YOU_HAVE_MESSAGES: "You have new messages",
  YOU_HAVE_NOTIFICATIONS: "You have new notifications",
  MESSAGE_FROM_USER: "{0}: {1}",
  SHOW: "Show",
  OPEN_ACTION: "Open link",
  CLOSE: "Close",
  SYSTEM_NOTIFICATION: "System notification",
  TEXT_MESSAGE: "Text message",
  DELETE: "Delete",
  RESOLVE: "Resolve",
  NO_DATA: "There's nothing to show.",
  EXECUTE_ACTION_FAILED: "Action execution failed.",
  MESSAGE_SEND: "Message was successfully send.",
  ERROR: "Error",
  ERROR_CODE: "Error code",
  ERROR_MESSAGE: "An error occured in the application.",
  ERROR_OCCURED: "An error occured in the '{0}' module.",
  ERROR_REPORT: "Report error",
  ERROR_REPORTED: "Error has been reported",
  ERROR_ALREADY_REPORTED: "Error is already repored",
  ERROR_UNKNOWN: "Unknown error",
  ERROR_TRY_IT_LATER: "The requested action cannot be performed at this time. Please try again later.",
  ERROR_UNKNOWN_OCCURED:
    "The requested action cannot be performed at this time. Please try again later or create a JIRA record.",
  ERROR_SPECIFIC_OCCURED:
    "There is a problem in the application that can be solved. Click on the 'Help' button to find out more about the problem and how to solve it. If the problem persists, create a JIRA record.",
  REPORT_TO_JIRA: "Report to JIRA",
  JIRA_SPECIFICATION: "Please describe the error. Your comment will make resolving of the defect easier and faster.",
  KNOWLEDGE_BASE: "Knowledge Base",
  DETAIL: "Detail",
  CANCEL: "Cancel",
  CREATE: "Create",
  USERNAME: "Username",
  EMAIL: "E-mail",
  REALM_ACCESS: "Roles",
  COPY_TOKEN: "Copy token",
  COPIED: "Copied",
  PROFILE: "Profile",
  DELETE_NOTIFICATION: "Delete notification",
  STOP_SHOW: "Hide these notifications",
  UNSUBSCRIBE: "Unsubscribe",
  UNSUBSCRIBE_SUCCESS: "subscription '{0}' logged out.",
  SUBSCRIBE_SUCCESS: "Subscription '{0}' logged in.",
  SUBSCRIBE_SETTINGS: "Subscribe settings",
  LOGIN_SERVER_UNREACHABLE: "The login server is not available.",
  LOGIN_ERROR: "An error occurred while logging in. ",
  SEND_COMMENT: "Send comment",
  COMMENT: "Comment",
  COMMENT_SENT: "Comment sent",
  SEND: "Send",
  FEEDBACK: "Feedback",
  SCANNING: "Scanning",
  WRITING: "Writting",
  USE: "Use",
  SCAN_AGAIN: "Scann again",
  TAKING_PICTURE: "Photo",
  TAKE_PICTURE: "Capture",
  TRY_AGAIN: "Try again",
  TRY_IT_LATER: "Please, try it later.",
  LOGOUT_ERROR: "An error occurred while logging out.",
  NFC_INITIALIZING: "Initializing...",
  NFC_NO_NFC: "The device you are using doesn't appear to have NFC or, the application hasn't been set up correctly.",
  NFC_NOT_ENABLED:
    "NFC is not enabled on your device. Click the button bellow to open your device's settings, then activate NFC.",
  NFC_WAITING_FOR_ENABLED: "Please click the button below once you have enabled NFC.",
  NFC_WAITING_FOR_TAG: "Move the device close to the NFC tag.",
  NFC_NO_DATA: "NFC tag is empty.",
  NFC_GO_TO_NFC: "Go to NFC Settings",
  NFC_INIT_NFC: "Initialize NFC",
  NFC_DATA_WRITE_SUCCESS: "NFC transmission was successful.",
  NFC_DATA_WRITE_ERROR: "NFC transmission failed.",
  NFC_ERROR: "An error occurred while trying to open the NFC Settings.",
  NFC_LOCK_SUCCESS: "The NFC tag has been permanently locked.",
  NFC_LOCK_FAILURE: "NFC tag permanent lock failed.",
  NFC_LOCK_WARNING: "Warning this is permanent and can not be undone.",
  NFC_PERMANENT_LOCK_TAG: "Permanent lock",
  TILE: "Tile",
  ENVIROMENT: "Enviroment",
  BACK: "Back",
  MODULE: "Module",
  MODULE_INCOPATIBILITY_DETECTED: "Module '{0}' could not be started. An incompatibility was detected.",
  MODULE_INCOPATIBLE_EVENT: "Unconfirmed event '{0}' at module '{1}'.",
  MODULE_MISSING_DEPENDENCY: "Module {0} contains a dependency on module {1}.",
  CONFIG_FILE_MISSING: "Configuration file is missing at module '{0}'.",
  NETWORK_STATUS_OFFLINE: "The network connection has been lost!",
  NETWORK_STATUS_ONLINE: "The network connection has been restored.",
  LOGIN_REQUESTED: "Sign in if you can",
  ACCESS_TOKEN_IS_EXPIRING:
    "The session will expire in 5 minutes. The application requires a network connection to continue.",
  ACCESS_TOKEN_IS_EXPIRING_AVAIL:
    "Session expires in 5 minutes. Network connection is required to extend the online session",
  PIN: "Offline login with PIN",
  PIN_BIOMETRICS: "Offline login with authorization",
  PIN_BIOMETRICS_INFO:
    "To use the app without access to the internet (offline), you must be authorised. The alternative is to use the Online Login form, but this requires an internet/data connection.",
  PIN_BIOMETRICS_USE: "Login with authorization",
  PIN_INFO:
    "To use the app without internet access (offline), you must enter a PIN (personal identification number). An alternative is to use the Online login form, but this requires an internet/data connection.",
  LICENSE_PROBLEM_TITLE: "Licencing problem",
  LICENSE_EXPIRING: "The module license is about to expire. {0}.",
  LICENSE_EXPIRED: "The module license has expired.",
  LICENSE_NOTIFICATION: "The expiration of the module's license is approaching: {0}",
  LICENSE_ERROR_NOTIFICATION: "The module's license has expired: {0}",
  PIN_USE: "Login with PIN",
  PIN_BIOMETRICS_TITLEDIALOG: "Authorization",
  PIN_BIOMETRICS_DESCDIALOG: "Authorize...",
  KEYCLOAK_USE: "Log in with the Online form",
  PIN_ERROR: "The entered PIN is not valid",
  SW_LOADING: "Preparing application (service worker)",
  MODULE_UPDATE_AVAILABLE: "Module update is available",
  WEBCONTAINER_UPDATE_AVAILABLE: "A webcontainer update is available",

  // texty pro drawer MachineSettings
  WEBCONTAINER_SETTINGS: "User settings of the application.",
  LOCAL_SETTINGS: "Local settings",
  MACHINE_NAME: "Station name",
  MACHINE_PLACEHOLDER: "Enter a name for this device.",
  TILE_SETTINGS: "Tile display settings",
  TILE_REARRANGE: "Tile move mode and widget activation",
  TILE_REARRANGE_INFO: "In this mode, tile and widget links are deactivated.",
  TILE_UNCOMPATIBILITY: "Show missing component alerts",
  TILE_UNCOMPATIBILITY_INFO: "The tiles will display an information icon describing the incompatibility.",
  SERVICEWORKER_ACTIVE: "Active serviceWorker in debug mode",
  WHISPERER_ISNOT_ACTIVE: "Whisperer for module '{0}' is not active.",
  ERROR_HELP_IS_MISSING: "Error help could not be loaded.",
  ABOUT_US: "About us",
  CONTACTS: "Contacts",
  BRANCH: "Branch",
  FAQ: "FAQ",
  INFORMATION: "Information",
  BUSINESS_DEPARTMENT: "Business department",
};
export default enUS;
