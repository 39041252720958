/* eslint-disable import/prefer-default-export */
import React from "react";

import { Redirect, Route, Switch } from "react-router-dom";

import { RegisteredModule } from "@elx-element/common/types";

import { RouteModel } from "../../types";

import SilentRenew from "../auth/SilentRenew";
import ModuleLoader from "../moduleLoader";

/**
 * Privátní routa - je vyžadováno přihlášení
 */
const PrivateRoute = ({ component, ...rest }: any) => (
  <Route
    {...rest}
    exact
    render={props =>
      rest.userAuthorized ? (
        <div>{React.createElement(component, props)}</div>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

interface Props {
  userAuthorized: boolean;
  routes: RouteModel[];
  modules: RegisteredModule[];
  modulesLoaded: boolean;
  modulePath?: string;
}

/**
 * Definice routování aplikace.
 * @param props
 */
const Routes = (props: Props) => (
  <Switch>
    <Route path="/silent-renew" element={<SilentRenew />} />
    <Route exact path="/">
      <Redirect to={`/${props.routes.find(r => r.default)?.route}`} />
    </Route>
    {props.routes.map((r, i) =>
      r.private ? (
        <PrivateRoute
          exact
          path={`/${r.route}`}
          key={`route${i}`}
          component={r.component}
          userAuthorized={props.userAuthorized}
        />
      ) : (
        <Route path={`/${r.route}`} key={`route${i}`} component={r.component} />
      )
    )}

    {props.modulesLoaded &&
      props.modules
        .filter(x => x.externalModule)
        .map((item, i) => (
          <Route
            path={`/${item.route}`}
            key={`route${i}`}
            render={() => (
              <ModuleLoader
                moduleId={item.moduleId}
                modulePath={props.modulePath}
                userAuthorized={props.userAuthorized}
              />
            )}
          />
        ))}

    {props.modulesLoaded && (
      <Route path="*">
        <Redirect to="/" />
      </Route>
    )}
  </Switch>
);
export default Routes;
