import { Box, Typography, useTheme } from "@mui/material";

import useTexts from "../../hooks/useTexts";

import useStyles from "./styles";

const Faq = () => {
  const { classes } = useStyles();
  const theme = useTheme();
  const texts = useTexts();
  return (
    <Box className={classes.content}>
      <Typography variant="h2" marginBottom={theme.spacing(2)}>
        {texts.FAQ}
      </Typography>
      <Typography variant="body1">{texts.NO_DATA}</Typography>
    </Box>
  );
};

export default Faq;
