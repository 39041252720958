import React, { useEffect } from "react";

import { batch, useDispatch, useSelector, useStore } from "react-redux";

import { Backdrop, CircularProgress } from "@mui/material";

import { useElementContext } from "@elx-element/common/elementContext";
import { CommonErrors, Notification } from "@elx-element/common/enums";
import { dispatchNotificationEvent } from "@elx-element/common/events/dispatchers";
import { NotificationEventModel } from "@elx-element/common/events/types";
import { throwSpecificError } from "@elx-element/common/logger";
import { getModulesAlerts } from "@elx-element/common/storage";
import { format } from "@elx-element/common/utils";

import { AppState } from "../../store";
import { selectRegisteredModules } from "../../store/main/selectors";
import { setActiveModule } from "../../store/main/slice";

import useTexts from "../../hooks/useTexts";

import History from "../../history";

interface Props {
  // Identifikátor modulu
  moduleId: string;
  // Absolutní cesta ke zdrojovým souborům modulu. Pokud je zadáná, stahují se moduly ze serveru.
  // V opačném případě se načítají z lokálního adresáře publis/modules (pouze z důvodu vývoje.)
  modulePath?: string;
  // Hlídání privátního přístupu
  userAuthorized: boolean;
}

/**
 * Připojí script element - js bundle
 */
const connectScript = (url: string) => {
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  script.setAttribute("data-script-type", "connected-module-script");
  if (!document.body.contains(script)) {
    document.body.appendChild(script);
  }
};

/**
 * Připojí link element - css bundle
 */
const connectStylesheet = (url: string) => {
  const stylesheet = document.createElement("link");
  stylesheet.href = url;
  stylesheet.rel = "stylesheet";
  stylesheet.type = "text/css";
  stylesheet.setAttribute("data-script-type", "connected-module-script");
  document.body.appendChild(stylesheet);
};

/**
 * Zpracovává entrypoints definované v assets-manifest.json modulu. Podle typu je použitá potřebná funkce pro připojení ke stránce.
 * @param route
 * @param entrypoint
 */
const connectManifestDependency = (route: string, entrypoint: string, modulePath?: string) => {
  if (entrypoint.includes("/css/")) {
    connectStylesheet(`${modulePath ?? ""}/modules/${route}/${entrypoint}`);
  } else if (entrypoint.includes("/js/")) {
    connectScript(`${modulePath ?? ""}/modules/${route}/${entrypoint}`);
  }
};

/**
 * Komponenta externího modulu.
 * Provede připojení zdrojových souborů externího modulu do DOMu stránky.
 * @param props
 */
const ModuleView: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState<boolean>(true);
  const module = useSelector(selectRegisteredModules).find(x => x.moduleId === props.moduleId);
  const texts = useTexts();
  const store = useStore<AppState>();
  const { checkTokenAnyRoleExists } = useElementContext();

  const checkAuthorized = () => {
    if (!props.userAuthorized) {
      console.debug("[Redirect] User not authorized. Redirecting to login.");
      History.push("/login");
    }
  };

  const checkPermission = () => {
    if (module?.requiredPermission && !checkTokenAnyRoleExists(module?.requiredPermission)) {
      console.debug(`[Redirect] Insufficient rights to view the module ${props.moduleId}. Redirecting to dashboard.`);
      History.push("/");
    }
  };

  const checkDependency = () => {
    // test na chyby v kompatibilitě. Pokud ve storage existují záznamy o kritických problémech, pak modul nedovolíme spustit.
    const criticalIncompatibilityExists = getModulesAlerts().some(x => x.moduleId === props.moduleId && x.required);
    if (criticalIncompatibilityExists) {
      dispatchNotificationEvent(
        new NotificationEventModel(format(texts.MODULE_INCOPATIBILITY_DETECTED, props.moduleId), Notification.error)
      );
      History.push("/");
    }
  };

  const checkLicence = () => {
    // Test na expiraci licence. Nedovolí otevřít modul, jehož licence vyexpirovala.
    if (module?.licenseExpired()) {
      console.debug(`[Redirect]  Licence of ${props.moduleId} expired. Redirecting to dashboard.`);
      History.push("/");
    }
  };

  useEffect(() => {
    checkAuthorized();
    checkPermission();
    checkLicence();
    checkDependency();
    // zapamatování otevřeného modulu
    batch(() => {
      dispatch(setActiveModule(props.moduleId));
    });
    // načtení konfigurací modulu - konfigurace musí existovat
    connectScript(`${props.modulePath ?? ""}/modules/${props.moduleId}/configuration.js?v=${Date.now()}`);

    // nastavení čísla verze a sestavení
    fetch(`${props.modulePath ?? ""}/modules/${props.moduleId}/interface.json?v=${Date.now()}`)
      .then(response => response.json())
      .then(jsonData => {
        document.querySelector("meta[name='module-build']")!.setAttribute("content", jsonData.buildNumber ?? "");
        document.querySelector("meta[name='module-version']")!.setAttribute("content", jsonData.version ?? "");
      })
      .catch(_ => {
        throwSpecificError(
          CommonErrors.missingDependency,
          `File is missing in location ${props.modulePath ?? ""}/modules/${props.moduleId}/interface.json`
        );
      });

    // nastavené zpoždení 200ms aby se získal čas na uložení konfigurace modulu do kolekce window.env
    setTimeout(() => {
      // on component mount - načtění skriptů modulu a připojení do stánky.
      fetch(`${props.modulePath ?? ""}/modules/${props.moduleId}/asset-manifest.json?v=${Date.now()}`)
        .then(response => response.json())
        .then(jsonData => {
          // připojení skriptů ke stránce
          jsonData.entrypoints.forEach((entrypoint: string) =>
            connectManifestDependency(props.moduleId, entrypoint, props.modulePath)
          );
        })
        .catch(_ => {
          setLoading(false);
          throwSpecificError(
            CommonErrors.missingDependency,
            `Module manifest is missing in location ${props.modulePath ?? ""}/modules/${
              props.moduleId
            }/asset-manifest.json`
          );
        })
        .finally(() => {
          setTimeout(() => {
            if (window.document.getElementById(`${props.moduleId.toLocaleLowerCase()}_module_loader`)) {
              setLoading(false);
            }
          }, 10000);
        });
    }, 200);

    // on component unmount - odstranění skriptů ze stránky při zavření modulu.
    return () => {
      // Odstranění záznamu o otevřeném modulu ze storu - stalo se, že došlo k přepnutí už na nový modul (Lukáš to možna už upravil, co mi ukazoval kód, nutno otestovat)
      const activeModule = store.getState().main.activeModuleId;
      if (!activeModule || activeModule === "dashboard" || activeModule === props.moduleId) {
        dispatch(setActiveModule(undefined));
      }

      // Likvidace připojených zdrojových knihoven, odstraní se z DOMu. (js a css bundly)
      document.querySelectorAll("[data-script-type='connected-module-script']").forEach(e => {
        e.remove();
      });
      // Likvidace drawerů a popup oken připojené aplikace
      // Drawery a popup okna se automaticky nezruší při odebrání zdrojových knihoven z DOMu. Je nutné je smazat manuálně.
      // Každý takový modul proto musí být identifikovatelný pomocí atributu data-app
      document.querySelectorAll(`[data-app='${props.moduleId}']`).forEach(e => {
        e.remove();
      });
      // Likvidace připojených stylů.
      // Každý modul generuje sadu css stylů a ty se připojí do DOMu stránky, defaultně na konec elementu HEAD.
      // Aby bylo možné je po opuštění modulu z DOMu odebrat, je nutné je připojit do specifického umístění.
      // Styly jsou renderovány do DIV elementu s ID WebContainer_module_styles
      if (document.getElementById("WebContainer_module_styles")) {
        document.getElementById("WebContainer_module_styles")!.innerHTML =
          '<noscript id="module-jss-insertion-point"></noscript>';
      }
      // Odstranění záznamu verze modulu z meta-description stránky
      document.querySelector("meta[name='module-version']")!.setAttribute("content", "");
      document.querySelector("meta[name='module-build']")!.setAttribute("content", "");
    };
  }, [props.userAuthorized]);

  // render placeholderů pro styly modulu i samotný modul.
  return (
    <React.Fragment>
      <div id="WebContainer_module_styles">
        <noscript id="module-jss-insertion-point" />
      </div>
      <div id={`${props.moduleId.toLocaleLowerCase()}root`} className="flexContainer">
        {loading && (
          <Backdrop open id={`${props.moduleId.toLocaleLowerCase()}_module_loader`}>
            <CircularProgress />
          </Backdrop>
        )}
      </div>
    </React.Fragment>
  );
};

export default ModuleView;
