/* eslint-disable camelcase */
import { UserManager, UserManagerSettings } from "oidc-client-ts";

import CustomWebStorageStateStore from "./CustomWebStorageStateStore";

const userManagerConfig: UserManagerSettings = {
  client_id: window.env.webcontainer.KEYCLOAK_CLIENT_ID,
  scope: window.env.webcontainer.KEYCLOAK_SCOPE,
  authority: window.env.webcontainer.AUTH_URL,
  automaticSilentRenew: true,
  loadUserInfo: true,
  monitorSession: true,
  redirect_uri: `${window.location.origin}/callback`,
  post_logout_redirect_uri: `${window.location.origin}/close`,
  silent_redirect_uri: `${window.location.origin}/silent-renew`,
  userStore: new CustomWebStorageStateStore(),
};

const userManager = new UserManager(userManagerConfig);

// Registrace eventu pro výmaz session storage po expiraci tokenu.
userManager.events.addAccessTokenExpired(async () => {
  console.debug("[Element]: AccessTokenExpired");
  // Pokus o potlačení generování chyb po probuzení PC z režimu spánku, přičemž v mezičase došlo k vypršení tokenu.
  // Za normálních okolností, kdy funguje obnova tokenu by tato situace neměla nastat.
  // V krajních případech, kdy se aplikace chová nestandardně, vymažeme session storage a přesměrujeme na login stránku.
  sessionStorage.clear();
  window.location.href = `${window.location.origin}/login`;
});

// Informativní zmínka do logu o chybě při obnovení tokenu.
userManager.events.addSilentRenewError(async () => {
  console.debug("[Element]: SilentRenewError");
});

userManager.events.addUserSignedOut(async () => {
  console.debug("[Element]: UserSignedOut");
  sessionStorage.clear();
});

export default userManager;
