import Lang from "./lang";

const huHU: Lang = {
  DASHBOARD: "Vezérlőpult",
  COPYRIGHT_TEXT: "E LINKX a.s.",
  COPYRIGHT_LINK: "https://www.elinkx.cz",
  ACTIVE_MODULES: "Telepített modulok",
  VERSION: "Verziószám",
  BUILD: "Összeállítás",
  SETTINGS: "Beállítások",
  LOGOUT: "Kijelentkezés",
  INCOMPATIBILITY: "Ez a modul inkompatibilis lehet.",
  INCOMPATIBILITY_NOTIFICATION: "Figyelmeztetés! Egyes modulok inkompatibilisek lehetnek.",
  UNSUPPORTED_COMMUNICATION_TYPE: "Nem támogatott kommunikációs típus.",
  UNSUPPORTED_ACTION_DATA: "Műveleti adathiba.",
  DATETIME: "Dátum és idő",
  TYPE: "Típus",
  SPECIFICATION: "Műszaki adatok",
  RANK: "Megrendelés",
  BACK_TO_DASHBOARD: "Vissza a VEZÉRLŐPULTHOZ",
  NOTIFICATION: "Értesítések",
  MESSAGES: "Üzenetek",
  YOU_HAVE_MESSAGES: "Új üzenetei vannak",
  YOU_HAVE_NOTIFICATIONS: "Új értesítései vannak",
  MESSAGE_FROM_USER: "{0}: {1}",
  SHOW: "Megjelenít",
  OPEN_ACTION: "Hivatkozás megnyitása",
  CLOSE: "Bezárás",
  SYSTEM_NOTIFICATION: "Rendszerértesítés",
  TEXT_MESSAGE: "Szöveges üzenet",
  DELETE: "Törlés",
  RESOLVE: "Megoldás",
  NO_DATA: "Nincs megjelenítendő adat.",
  EXECUTE_ACTION_FAILED: "Művelet végrehajtása sikertelen.",
  MESSAGE_SEND: "Az üzenet sikeresen elküldve.",
  ERROR: "Hiba",
  ERROR_CODE: "Hibakód",
  ERROR_MESSAGE: "Hiba történt az alkalmazásban.",
  ERROR_OCCURED: "Hiba történt a(z) „{0}” modulban.",
  ERROR_REPORT: "Hiba jelentése",
  ERROR_REPORTED: "Hiba bejelentve",
  ERROR_ALREADY_REPORTED: "A hibát már jelentették",
  ERROR_UNKNOWN: "Ismeretlen hiba",
  ERROR_TRY_IT_LATER: "A kért művelet jelenleg nem végrehajtható. Kérjük, próbálja meg később.",
  ERROR_UNKNOWN_OCCURED:
    "The requested action cannot be performed at this time. Please try again later or create a JIRA record.",
  ERROR_SPECIFIC_OCCURED:
    "There is a problem in the application that can be solved. Click on the 'Help' button to find out more about the problem and how to solve it. If the problem persists, create a JIRA record.",
  REPORT_TO_JIRA: "Jelentés a JIRA-ba",
  JIRA_SPECIFICATION: "Kérjük, írja le a hibát. Az Ön észrevétele megkönnyíti és felgyorsítja a hiba megoldását.",
  KNOWLEDGE_BASE: "Tudásbázis",
  DETAIL: "Részletek",
  CANCEL: "Mégse",
  CREATE: "Létrehoz",
  USERNAME: "Felhasználónév",
  EMAIL: "E-mail",
  REALM_ACCESS: "Szerepkörök",
  COPY_TOKEN: "Token másolása",
  COPIED: "Másolva",
  PROFILE: "Profil",
  DELETE_NOTIFICATION: "Értesítés törlése",
  STOP_SHOW: "Ezen értesítések elrejtése",
  UNSUBSCRIBE: "Leiratkozás",
  UNSUBSCRIBE_SUCCESS: "„{0}” előfizetés kijelentkezett.",
  SUBSCRIBE_SUCCESS: "„{0}” előfizetés bejelentkezett.",
  SUBSCRIBE_SETTINGS: "Feliratkozás beállítások",
  LOGIN_SERVER_UNREACHABLE: "A bejelentkezési szerver nem elérhető.",
  LOGIN_ERROR: "Hiba történt a bejelentkezés során. ",
  SEND_COMMENT: "Hozzászólás küldése",
  COMMENT: "Hozzászólás",
  COMMENT_SENT: "Hozzászólás elküldve",
  SEND: "Küldés",
  FEEDBACK: "Visszajelzés",
  SCANNING: "Beolvasás",
  WRITING: "Írás",
  USE: "Használ",
  SCAN_AGAIN: "Beolvasás újra",
  TAKING_PICTURE: "Fényképezőgép",
  TAKE_PICTURE: "Rögzítés",
  TRY_AGAIN: "Próbálja újra",
  TRY_IT_LATER: "Kérjük, próbálja meg később.",
  LOGOUT_ERROR: "Hiba történt a kijelentkezés során.",
  NFC_INITIALIZING: "Inicializálás…",
  NFC_NO_NFC: "Az Ön által használt eszköz nem rendelkezik NFC-vel, vagy az alkalmazás nincs megfelelően beállítva.",
  NFC_NOT_ENABLED:
    "NFC is not enabled on your device. Click the button bellow to open your device's settings, then activate NFC.",
  NFC_WAITING_FOR_ENABLED: "Kérjük, kattintson az alábbi gombra, ha engedélyezte az NFC-t.",
  NFC_WAITING_FOR_TAG: "Helyezze a készüléket az NFC-címke közelébe.",
  NFC_NO_DATA: "Az NFC-címke üres.",
  NFC_GO_TO_NFC: "Tovább az NFC-beállításokhoz",
  NFC_INIT_NFC: "NFC inicializálása",
  NFC_DATA_WRITE_SUCCESS: "Az NFC-adatátvitel sikeres volt.",
  NFC_DATA_WRITE_ERROR: "Az NFC-adatátvitel sikertelen.",
  NFC_ERROR: "Hiba történt az NFC-beállítások megnyitásakor.",
  NFC_LOCK_SUCCESS: "Az NFC-címke véglegesen zárolva lett.",
  NFC_LOCK_FAILURE: "Az NFC-címke végleges zárolása sikertelen.",
  NFC_LOCK_WARNING: "Figyelmeztetés: ez végleges, és nem visszavonható.",
  NFC_PERMANENT_LOCK_TAG: "Végleges zárolás",
  TILE: "Csempe",
  TILE_SETTINGS: "Csempe megjelenítési beállítások",
  TILE_REARRANGE: "Csempe mozgatási mód és eszköz aktiválás",
  TILE_REARRANGE_INFO: "Ebben az üzemmódban a csempék és eszközök hivatkozásai ki vannak kapcsolva.",
  TILE_UNCOMPATIBILITY: "Hiányzó komponens figyelmeztetések megjelenítése",
  TILE_UNCOMPATIBILITY_INFO: "A csempéken megjelenik egy tájékoztató ikon, amely ismerteti az inkompatibilitás okát.",
  ENVIROMENT: "Környezet",
  BACK: "Vissza",
  MODULE: "Modul",
  MODULE_INCOPATIBILITY_DETECTED: "Inkompatibilitás a(z) „{0}” modullal.",
  MODULE_INCOPATIBLE_EVENT: "Meg nem erősített „{0}” esemény a(z) „{1}” modulban.",
  CONFIG_FILE_MISSING: "Konfigurációs fájl hiányzik a(z) „{0}” modulból.",
  NETWORK_STATUS_OFFLINE: "A hálózati kapcsolat megszakadt!",
  NETWORK_STATUS_ONLINE: "A hálózati kapcsolat helyreállt.",
  ACCESS_TOKEN_IS_EXPIRING:
    "The session will expire in 5 minutes. The application requires a network connection to continue.",
  LICENSE_PROBLEM_TITLE: "Licencelési probléma",
  LICENSE_EXPIRING: "A modul licence hamarosan lejár. {0}.",
  LICENSE_EXPIRED: "A modul licence lejárt.",
  LICENSE_NOTIFICATION: "Közeledik a modul licencének lejárta: {0}",
  LICENSE_ERROR_NOTIFICATION: "A modul licence lejárt: {0}",
  PIN: "Offline bejelentkezés PIN-kóddal",
  PIN_BIOMETRICS: "Offline bejelentkezés jogosultsággal",
  PIN_BIOMETRICS_INFO:
    "Az alkalmazás internet-hozzáférés nélküli (offline) használatához engedélyre van szüksége. Vagy használhatja az online bejelentkezési űrlapot, de ehhez internet-/adatkapcsolat szükséges.",
  PIN_BIOMETRICS_USE: "Bejelentkezés jogosultsággal",
  PIN_INFO:
    "Az alkalmazás internet-hozzáférés nélküli (offline) használatához meg kell adnia egy PIN-kódot (személyes azonosító szám). Vagy használhatja az online bejelentkezési űrlapot, de ehhez internet/adatkapcsolat szükséges.",
  PIN_USE: "Bejelentkezés PIN-kóddal",
  PIN_BIOMETRICS_TITLEDIALOG: "Hitelesítés",
  PIN_BIOMETRICS_DESCDIALOG: "Jóváhagyás...",
  KEYCLOAK_USE: "Bejelentkezés online űrlappal",
  PIN_ERROR: "A megadott PIN-kód érvénytelen",
  SW_LOADING: "Alkalmazás előkészítése (szervizmunkás)",
  MODULE_UPDATE_AVAILABLE: "Elérhető modulfrissítés",
  WEBCONTAINER_UPDATE_AVAILABLE: "Elérhető webcontainer frissítés",
  MODULE_MISSING_DEPENDENCY: "A(z) {0} modul függ a(z) {1} modultól.",
  ACCESS_TOKEN_IS_EXPIRING_AVAIL:
    "A munkamenet 5 perc múlva lejár. Az online munkamenet meghosszabbításához hálózati kapcsolat szükséges",
  LOGIN_REQUESTED: "Jelentkezzen be, ha van rá lehetősége",
  WEBCONTAINER_SETTINGS: "Az alkalmazás felhasználói beállításai.",
  LOCAL_SETTINGS: "Helyi beállítások",
  MACHINE_NAME: "Munkaállomás neve",
  MACHINE_PLACEHOLDER: "Adjon meg egy nevet az eszköznek.",
  SERVICEWORKER_ACTIVE: "serviceWorker aktív hibakeresési üzemmódban",
  WHISPERER_ISNOT_ACTIVE: "Whisperer for module '{0}' is not active.",
  ERROR_HELP_IS_MISSING: "Hiba súgó nem tölthető be.",
  ABOUT_US: "Rólunk",
  CONTACTS: "Kapcsolatok",
  BRANCH: "Ág",
  FAQ: "FAQ",
  INFORMATION: "Információ",
  BUSINESS_DEPARTMENT: "Üzleti osztály",
};
export default huHU;
