/* eslint-disable import/prefer-default-export */
import { useEffect } from "react";

import { useDispatch } from "react-redux";

import { registerNfcWriterControlEventListener } from "@elx-element/common/events/nfc";
import { NfcWriterControl } from "@elx-element/common/events/types";

import { clearNfcWriter, configureNfcWriter } from "../store/main/slice";

import { hideWebcontainerBackground, showWebcontainerBackground } from "../components/app/core";

/**
 * Systémové norifikace a zprávy - ikony zvonečku a obálky
 * @param enabled
 */
export const useNfcWriter = (enabled: boolean) => {
  const dispatch = useDispatch();
  /**
   * Handler změny state s nastavením NFC writeru
   * @param settings - nastavení writeru NfcWriterControl
   * @param dispatch
   */
  const handleNfcWriterControlEvent = (settings: CustomEventInit<NfcWriterControl>) => {
    // spuštění writeru
    if (settings.detail!.active) {
      hideWebcontainerBackground();
      dispatch(configureNfcWriter(settings.detail!));
    } else {
      // zavření writeru
      writerClose();
    }
  };

  /**
   * Zavření UI writeru
   * @param dispatch
   */
  const writerClose = () => {
    dispatch(clearNfcWriter());
    showWebcontainerBackground();
  };

  useEffect(() => {
    if (enabled) {
      // Listener pro NFC writer
      registerNfcWriterControlEventListener(event => handleNfcWriterControlEvent(event));
    }
  }, [enabled]);
};
