/* eslint-disable import/prefer-default-export */
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { apiMan } from "@elx-element/common/apiClient/apiMan";
import { Notification } from "@elx-element/common/enums";
import { dispatchNotificationEvent } from "@elx-element/common/events/dispatchers";
import {
  registerSubscribeNotificationByCodeListener,
  registerUnsubscribeNotificationByCodeListener,
  registerUnsubscribeNotificationListener,
} from "@elx-element/common/events/listeners";
import { NotificationEventModel } from "@elx-element/common/events/types";
import { format } from "@elx-element/common/utils";

import { ApplicationEventClient, NotificationModel, WebContainerClient } from "../types.generated";

import { selectSubcriptions } from "../store/message/selectors";
import { addApplicationEvents, addSubcriptions, removeNotificationByCode } from "../store/message/slice";

import useAbortController from "./useAbortController";
import useTexts from "./useTexts";

export const useNotificationSubscribeEvents = (enabled: boolean) => {
  const dispatch = useDispatch();
  const abortController = useAbortController();
  const subscriptions = useSelector(selectSubcriptions);
  const texts = useTexts();

  /**
   * Load odebíraných typů notifikací konkrétního uživatele
   * @param client
   * @param dispatch
   */
  const loadSubcriptions = async () => {
    const response = await apiMan(
      new WebContainerClient({ abortSignal: abortController.signal }).eventSubscriptionAll(1000, 0)
    );
    if (response) {
      dispatch(addSubcriptions(response));
    }
  };

  /**
   * Load všech typů notifikací
   * @param client
   * @param dispatch
   */
  const loadApplicationEvents = async () => {
    const response = await apiMan(
      new ApplicationEventClient({ abortSignal: abortController.signal }).applicationEvent()
    );
    if (response) {
      dispatch(addApplicationEvents(response));
    }
  };

  /**
   * Odhlášení odběru konkrétního typu notifikace pomocí notificationId. (Nemusí být v subscripcích.)
   * Po odhlášení jsou všechny výskyty daného typu odebrány ze zásobníku notifikací.
   */
  const handleUnsubscribeNotification = async (notification: CustomEventInit<NotificationModel>) => {
    if (notification.detail?.code) {
      let success = true;
      try {
        await apiMan(
          new WebContainerClient({ abortSignal: abortController.signal }).eventSubscription2(notification.detail.id)
        );
        await loadSubcriptions();
        await dispatch(removeNotificationByCode(notification.detail.code));
      } catch {
        success = false;
      }
      if (success) {
        dispatchNotificationEvent(
          new NotificationEventModel(
            texts.UNSUBSCRIBE_SUCCESS.replace("{0}", notification.detail.code),
            Notification.success
          )
        );
      }
    }
  };

  /**
   * Odhlášení odběru konkrétního typu notifikace pomocí notificationCode.
   */
  const handleUnsubscribeNotificationCode = async (notificationCode: CustomEventInit<string>) => {
    if (notificationCode) {
      let success = true;
      try {
        const subscription = subscriptions.find(x => x.eventCodeGroup === notificationCode);
        if (subscription) {
          await apiMan(
            new WebContainerClient({ abortSignal: abortController.signal }).eventSubscription3(subscription.id)
          );
          await loadSubcriptions();
        }
      } catch {
        success = false;
      }
      if (success) {
        dispatchNotificationEvent(
          new NotificationEventModel(format(texts.UNSUBSCRIBE_SUCCESS, notificationCode), Notification.success)
        );
      }
    } else {
      throw new Error("[Element]: NotificationCode at function handleUnsubscribeNotificationCode cannot be undefined.");
    }
  };

  /**
   * Přihlášení odběru konkrétního typu notifikace pomocí notificationCode.
   */
  const handleSubscribeNotificationCode = async (notification: CustomEventInit<string>) => {
    if (notification.detail) {
      let success = true;
      try {
        await apiMan(
          new WebContainerClient({ abortSignal: abortController.signal }).eventSubscription(notification.detail)
        );
        await loadSubcriptions();
      } catch {
        success = false;
      }
      if (success) {
        dispatchNotificationEvent(
          new NotificationEventModel(format(texts.UNSUBSCRIBE_SUCCESS, notification), Notification.success)
        );
      }
    } else {
      throw new Error("[Element]: NotificationCode at function handleSubscribeNotification cannot be undefined.");
    }
  };

  useEffect(() => {
    if (enabled) {
      registerUnsubscribeNotificationListener(handleUnsubscribeNotification);
      registerSubscribeNotificationByCodeListener(handleSubscribeNotificationCode);
      registerUnsubscribeNotificationByCodeListener(handleUnsubscribeNotificationCode);
      loadApplicationEvents();
      loadSubcriptions();
    }
  }, [enabled]);
};
