import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  widget: {
    width: "100%",
    height: "100%",
    display: "block",
    float: "left",
    cursor: "pointer",
    textAlign: "center",
    backgroundColor: window.top!.env.themeV5.tileColor ?? theme.palette.primary.main,
    "& a": {
      display: "block",
      margin: "0 auto",
      padding: "1rem",
      paddingTop: "1.75rem",
      height: "100%",
      "& iframe": {
        paddingTop: theme.spacing(4),
      },
      "&:hover": {
        "& .tile-icon": {
          opacity: 0.75,
        },
      },
    },
    "& .tileIcon": {
      color: theme.palette.primary.contrastText,
      fontSize: "2.5rem",
      margin: "0 auto",
      cursor: "pointer",
    },
    "& span": {
      display: "block",
      color: theme.palette.primary.contrastText,
      cursor: "pointer",
      fontSize: "0.75rem",
    },
  },

  widgetHeader: {
    position: "absolute",
    width: "100%",
    height: 32,
    backgroundColor: window.top!.env.themeV5.tileColor ?? theme.palette.primary.main,
    top: 0,
    left: 0,
    "& .tileIconSmall": {
      color: theme.palette.primary.contrastText,
      fontSize: "1.5em",
      display: "inline-block",
      marginBottom: theme.spacing(-1),
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(-1),
    },
    "& .tileSpanSmall": {
      lineHeight: "32px",
      display: "inline-block",
    },
  },

  noPaddding: {
    "& a": {
      padding: 0,
    },
  },

  widgetHeaderDragged: {
    top: 2,
    left: 2,
    width: "calc(100% - 4px)",
  },

  navLink: {
    textDecoration: "none",
    color: "inherit",
  },
  widgetSelector: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2,
    "& button": {
      padding: 0,
      paddingTop: 2,
    },
  },
  dragSwitch: {
    position: "absolute",
    right: 8,
    top: 50,
    zIndex: 10,
  },
  dragedBox: {
    display: "block",
    margin: "0 auto",
    padding: "1rem",
    height: "100%",
    textDecoration: "none",
    color: "inherit",
    border: `2px solid ${theme.palette.grey.A200}`,
    cursor: "move",
    "& span": {
      cursor: "move",
    },
  },
  emptyWidget: {
    paddingTop: "80px",
  },
  widgetSelectorIcon: {
    color: theme.palette.grey.A200,
  },

  optionalAlertIcon: {
    color: theme.palette.warning.main,
    fontSize: "1.5rem",
    position: "absolute",
    top: "0",
    right: "0",
  },
  requiredAlertIcon: {
    color: theme.palette.error.main,
    fontSize: "1.5rem",
    position: "absolute",
    top: "0",
    right: "0",
  },
  alertsList: {
    paddingTop: theme.spacing(1),
    "& ul": {
      paddingLeft: theme.spacing(2),
    },
  },
  notAllowed: {
    cursor: "not-allowed",
    "& svg, label": {
      cursor: "not-allowed",
    },
  },
}));

export default useStyles;
