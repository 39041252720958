import Lang from "./lang";

const csCZ: Lang = {
  DASHBOARD: "Dashboard",
  COPYRIGHT_TEXT: "E LINKX a.s.",
  COPYRIGHT_LINK: "https://www.elinkx.cz",
  ACTIVE_MODULES: "Aktivní moduly",
  VERSION: "Verze",
  BUILD: "Sestavení",
  SETTINGS: "Nastavení",
  LOGOUT: "Odhlásit se",
  INCOMPATIBILITY: "Tento modul vykazuje nekompatibilitu",
  INCOMPATIBILITY_NOTIFICATION: "Upozornění! Některé moduly vykazují nekompatibilitu.",
  UNSUPPORTED_COMMUNICATION_TYPE: "Nepodporovaný typ komunikace.",
  UNSUPPORTED_ACTION_DATA: "Akci nelze provést. Chyba vstupních dat.",
  DATETIME: "Datum a čas",
  TYPE: "Typ",
  SPECIFICATION: "Upřesnění",
  RANK: "Pořadí",
  BACK_TO_DASHBOARD: "Zpět na DASHBOARD",
  NOTIFICATION: "Oznámení",
  MESSAGES: "Zprávy",
  YOU_HAVE_MESSAGES: "Máte nové zprávy",
  YOU_HAVE_NOTIFICATIONS: "Máte nové oznámení",
  MESSAGE_FROM_USER: "{0}: {1}",
  SHOW: "Zobrazit",
  OPEN_ACTION: "Otevřít odkaz",
  CLOSE: "Zavřít",
  SYSTEM_NOTIFICATION: "Systémová notifikace",
  TEXT_MESSAGE: "Textová zpráva",
  DELETE: "Smazat zprávu",
  RESOLVE: "Vyřešit",
  NO_DATA: "Není tu nic k zobrazení.",
  EXECUTE_ACTION_FAILED: "Spuštění požadované akce selhalo.",
  MESSAGE_SEND: "Zpráva byla úspěšně odeslána.",
  ERROR: "Chyba",
  ERROR_MESSAGE: "V aplikaci došlo k problému.",
  ERROR_OCCURED: "V modulu '{0}' došlo k problému.",
  ERROR_CODE: "Kód",
  ERROR_REPORT: "Nahlásit problém",
  ERROR_REPORTED: "Problém byl nahlášen v systému JIRA.",
  ERROR_ALREADY_REPORTED: "Problém již byl nahlášen v systému JIRA.",
  ERROR_UNKNOWN: "Neznámá chyba",
  ERROR_TRY_IT_LATER: "Požadovanou akci není možné v tuto chvíli vykonat. Prosím, zkuste to později.",
  ERROR_UNKNOWN_OCCURED:
    "Požadovanou akci není možné v tuto chvíli vykonat. Prosím, zkuste to později, nebo vytvořte JIRA záznam.",
  ERROR_SPECIFIC_OCCURED:
    "V aplikaci došlo k problému, který je možné vyřešit. Kliknutím na tlačítko 'Nápověda' zjistěte více o problému a způsobu jak jej vyřešit. V případě, že se problém nepodaří opravit, vytvořte JIRA záznam.",
  REPORT_TO_JIRA: "Hlášení JIRA záznamu",
  JIRA_SPECIFICATION: "Popište prosím vzniklý problém. Váš komentář usnadní a urychlí vyřešení.",
  KNOWLEDGE_BASE: "Nápověda",
  DETAIL: "Detail",
  CANCEL: "Zrušit",
  CREATE: "Vytvořit",
  USERNAME: "Uživatelské jméno",
  EMAIL: "E-mail",
  REALM_ACCESS: "Role",
  COPY_TOKEN: "Zkopírovat token",
  COPIED: "Zkopírováno",
  PROFILE: "Profil",
  DELETE_NOTIFICATION: "Smazat oznámení",
  STOP_SHOW: "Neodebírat tato oznámení",
  UNSUBSCRIBE: "Odhlásit odběr",
  UNSUBSCRIBE_SUCCESS: "Odběr notifikace '{0}' odhlášen.",
  SUBSCRIBE_SUCCESS: "Odběr notifikace '{0}' přihlášen.",
  SUBSCRIBE_SETTINGS: "Nastavení odběru notifikací",
  LOGIN_SERVER_UNREACHABLE: "Přihlašovací server není dostupný.",
  LOGIN_ERROR: "Při přihlašování došlo k chybě.",
  SEND_COMMENT: "Odeslat komentář",
  COMMENT: "Komantář",
  COMMENT_SENT: "Komentář byl odeslán.",
  SEND: "Odeslat",
  FEEDBACK: "Pomohla Vám tato nápověda?",
  SCANNING: "Skenování",
  WRITING: "Zápis",
  USE: "Použít",
  SCAN_AGAIN: "Skenovat znovu",
  TAKING_PICTURE: "Foto",
  TAKE_PICTURE: "Vyfotit",
  TRY_AGAIN: "Zkusit znovu",
  TRY_IT_LATER: "Zkuste to prosím později.",
  LOGOUT_ERROR: "Při odhlášení došlo k chybě.",
  NFC_INITIALIZING: "Inicializace...",
  NFC_NO_NFC: "Zdá se, že zařízení, které používáte, nemá NFC.",
  NFC_NOT_ENABLED:
    "NFC není ve vašem zařízení povoleno. Kliknutím na tlačítko níže otevřete nastavení zařízení a poté aktivujete NFC.",
  NFC_WAITING_FOR_ENABLED: "Pro povolení NFC klikněte na tlačítko níže.",
  NFC_WAITING_FOR_TAG: "Přibližte zařízení k NFC štítku.",
  NFC_NO_DATA: "NFC štítek je prázdný.",
  NFC_GO_TO_NFC: "Přejít k NFC nastavení",
  NFC_INIT_NFC: "Inicializace NFC",
  NFC_DATA_WRITE_SUCCESS: "NFC zápis byl úspěšný.",
  NFC_DATA_WRITE_ERROR: "NFC zápis se nezdařil.",
  NFC_ERROR: "Při pokusu o otevření nastavení NFC došlo k chybě.",
  NFC_LOCK_SUCCESS: "NFC štítek byl permanentně uzamčen.",
  NFC_LOCK_FAILURE: "Permanentní uzamčení NFC štítku se nezdařilo.",
  NFC_LOCK_WARNING: "POZOR: Tato operace je trvalá a nelze ji vrátit zpět.",
  NFC_PERMANENT_LOCK_TAG: "Trvale zamknout",
  TILE: "Dlaždice",
  ENVIROMENT: "Prostředí",
  BACK: "Zpět",
  MODULE: "Modul",
  MODULE_INCOPATIBILITY_DETECTED: "Modul '{0}' nelze spustit. Byla zjištěna nekompatibilita.",
  MODULE_INCOPATIBLE_EVENT: "Nepotvrzená událost '{0}' v modulu '{1}'.",
  MODULE_MISSING_DEPENDENCY: "Modul {0} obsahuje závislost na modulu {1}.",
  CONFIG_FILE_MISSING: "Nenalezen konfigurační soubor modulu '{0}'.",
  NETWORK_STATUS_OFFLINE: "Připojení k síti bylo přerušeno!",
  NETWORK_STATUS_ONLINE: "Připojení k síti bylo obnoveno.",
  ACCESS_TOKEN_IS_EXPIRING: "Relace vyprší za 5 minut. Aplikace vyžaduje připojení k síti aby bylo možné pokračovat.",
  ACCESS_TOKEN_IS_EXPIRING_AVAIL: "Relace vyprší do 5 minut. Pro prodloužení online provozu je nutné připojení k síti.",
  LOGIN_REQUESTED: "Přihlaste se, pokud můžete",
  PIN: "Offline přihlášení pomocí PINu",
  PIN_BIOMETRICS: "Offline přihlášení pomocí autorizace",
  PIN_BIOMETRICS_INFO:
    "Pro používání aplikace bez přístupu k internetu (offline) je nutné se autorizovat. Alternativou je se přihlásit Online formulářem, ten ale vyžaduje internetové / datové připojení.",
  PIN_BIOMETRICS_USE: "Přihlásit autorizací",
  LICENSE_PROBLEM_TITLE: "Problém s licencováním",
  LICENSE_EXPIRING: "Licence na užívání modulu expiruje {0}.",
  LICENSE_EXPIRED: "Licence na užívání modulu expirovala.",
  LICENSE_NOTIFICATION: "Blíží se expirace licence na užívání modulů: {0}",
  LICENSE_ERROR_NOTIFICATION: "Expirovala licence na užívání modulů: {0}",
  PIN_INFO:
    "Pro používání aplikace bez přístupu k internetu (offline) je nutné zadat PIN kód (osobní identifikační číslo). Alternativou je se přihlásit Online formulářem, ten ale vyžaduje internetové / datové připojení.",
  PIN_USE: "Přihlásit PINem",
  PIN_BIOMETRICS_TITLEDIALOG: "Autorizace",
  PIN_BIOMETRICS_DESCDIALOG: "Autorizujte se...",
  KEYCLOAK_USE: "Přihlásit Online formulářem",
  PIN_ERROR: "Zadaný PIN není platný",
  SW_LOADING: "Připravuji aplikaci (service worker)",
  MODULE_UPDATE_AVAILABLE: "Je dostupná aktualizace modulu",
  WEBCONTAINER_UPDATE_AVAILABLE: "Je dostupná aktulalizace webcontaineru",

  // texty pro drawer MachineSettings
  WEBCONTAINER_SETTINGS: "Uživatelské nastavení aplikace.",
  LOCAL_SETTINGS: "Lokální nastavení",
  MACHINE_NAME: "Název stanice",
  MACHINE_PLACEHOLDER: "Zadejte název tohoto zařízení.",
  TILE_SETTINGS: "Zobrazení dlaždic",
  TILE_REARRANGE: "Mód přesunu dlaždic a aktivace widgetů",
  TILE_REARRANGE_INFO: "V tomto módu jsou odkazy dlaždic a widgetů deaktivovány.",
  TILE_UNCOMPATIBILITY: "Zobrazovat upozornění na chybějící součásti",
  TILE_UNCOMPATIBILITY_INFO: "Dlaždice zobrazují informační ikonu s popisem nekompatibility.",
  SERVICEWORKER_ACTIVE: "Aktivní serviceWorker v debug režimu",
  WHISPERER_ISNOT_ACTIVE: "Našeptávač modulu '{0}' není aktivní.",
  ERROR_HELP_IS_MISSING: "Nápovědu k chybě se nepodařilo načíst.",
  ABOUT_US: "O nás",
  CONTACTS: "Kontakty",
  BRANCH: "Pobočka",
  FAQ: "FAQ",
  INFORMATION: "Informace",
  BUSINESS_DEPARTMENT: "Obchodní oddělení",
};
export default csCZ;
