import { makeStyles } from "tss-react/mui";

import { selectClasses } from "@mui/material/Select";

type HeaderClassKey =
  | "languageSelectButton"
  | "title"
  | "titleIcon"
  | "webcontainerSettingsButtonPlaceholder"
  | "shakeEffect"
  | "popoverRoot"
  | "appBar"
  | "appBarOpen"
  | "toolbar"
  | "toolbarOpen"
  | "mainNavButton"
  | "mainNavButtonContainer"
  | "headerChip"
  | "machineIcon";

const drawerWidth = 240;
const useHeaderStyles = makeStyles<void, HeaderClassKey>({ name: "selectorName" })((theme, _, classes) => ({
  languageSelectButton: {
    [`& .${selectClasses.select}`]: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      [theme.breakpoints.down("sm")]: {
        paddingRight: `${theme.spacing(2)}!important`,
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "small",
      [`& .${selectClasses.icon}`]: {
        display: "none",
      },
    },
    marginRight: theme.spacing(0.5),
  },
  title: {
    flexGrow: 1,
  },
  titleIcon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
    "& svg": {
      fontSize: "x-large",
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.down("lg")]: {
      marginLeft: 0,
    },
  },
  webcontainerSettingsButtonPlaceholder: {
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  shakeEffect: {
    "&:hover": {
      "& svg": {
        animation: "shake 0.82s cubic-bezier(.36,.07,.19,.97) both",
        transform: "translate3d(0, 0, 0)",
        backfaceVisibility: "hidden",
        perspective: "1000px",
      },
    },
  },
  popoverRoot: {
    zIndex: theme.zIndex.modal + 1,
  },
  /** Css pro hlavičku hlavního menu */
  appBar: {
    [theme.breakpoints.up("md")]: {
      zIndex: theme.zIndex.drawer,
    },
    backgroundColor: theme.palette.background.paper,
    border: "none",
  },
  appBarOpen: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: "none",
  },
  toolbar: {
    minHeight: 50,
    paddingRight: theme.spacing(1),
    marginLeft: theme.spacing(6),
    [theme.breakpoints.up("sm")]: {
      minHeight: 50,
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      paddingLeft: 0,
    },
  },
  toolbarOpen: {
    marginLeft: 0,
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(2),
    },
  },
  mainNavButton: {
    color: theme.palette.primary.contrastText,
    marginLeft: theme.spacing(0.5),
  },
  mainNavButtonContainer: {
    display: "flex",
    backgroundColor: window.top!.env.themeV5.logoBackgroundColor ?? theme.palette.primary.main,
    backgroundPosition: "left",
    backgroundRepeat: "no-repeat",
    marginRight: theme.spacing(2),
  },
  headerChip: { marginLeft: theme.spacing(1) },
  machineIcon: {
    [`.${classes.headerChip} &`]: {
      width: 16,
      height: 16,
    },
  },
  badgeRoot: {
    color: "inherit",
  },
}));
export default useHeaderStyles;
