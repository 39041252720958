import { useDispatch } from "react-redux";

import { Box, Dialog, IconButton } from "@mui/material";

import BaseIcon from "@elx-element/ui/DataDisplay/BaseIcon";
import TabView from "@elx-element/ui/DataDisplay/TabView";

import { mdiClose } from "@mdi/js";

import { setInfoPopupOpen } from "../../store/main/slice";

import useTexts from "../../hooks/useTexts";

import useStyles from "./styles";

import Contacts from "./Contacts";
import Faq from "./Faq";

const InfoModal = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const texts = useTexts();

  return (
    <Dialog
      open
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      onClose={() => dispatch(setInfoPopupOpen(false))}
      classes={{ paper: classes.paper }}
    >
      <Box className={classes.root}>
        <IconButton onClick={() => dispatch(setInfoPopupOpen(false))} aria-label="close" className={classes.closeBtn}>
          <BaseIcon data={mdiClose} />
        </IconButton>
        <TabView
          views={[
            {
              id: "contacts",
              label: texts.ABOUT_US,
              view: <Contacts />,
            },
            {
              id: "faq",
              label: texts.FAQ,
              view: <Faq />,
            },
          ]}
        />
      </Box>
    </Dialog>
  );
};

export default InfoModal;
