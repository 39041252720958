import { useEffect, useRef } from "react";

const useAbortController = () => {
  const abortController = useRef(new AbortController());
  useEffect(
    () => () => {
      abortController.current.abort();
    },
    []
  );
  return abortController.current;
};

export default useAbortController;
