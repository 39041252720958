/* eslint-disable @typescript-eslint/no-unused-vars */
import { WebStorageStateStore } from "oidc-client-ts";

import { authStorageKey } from "@elx-element/common";

export default class CustomWebStorageStateStore extends WebStorageStateStore {
  constructor() {
    super({ store: window.sessionStorage, prefix: "" });
  }

  // Přepis metod get a set, aby používaly pevný klíč "authStorage" z knihovny @elx-element/common
  get(key: string) {
    return super.get(authStorageKey);
  }

  set(key: string, value: string) {
    return super.set(authStorageKey, value);
  }

  remove(key: string) {
    return super.remove(authStorageKey);
  }
}
