/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-promise-reject-errors */
import { registerPlugin, WebPlugin } from '@capacitor/core';

import {
  CameraPreviewFlashMode,
  CameraPreviewOptions,
  CameraPreviewPictureOptions,
  MachineLearningPlugin,
  ScanOptions,
} from './definitions';

export class MachineLearningWeb
  extends WebPlugin
  implements MachineLearningPlugin {
  constructor() {
    super({
      name: 'MachineLearning',
      platforms: ['web'],
    });
  }

  async start(options: CameraPreviewOptions): Promise<{}> {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices.getUserMedia({ audio: true, video: true });

      const video = document.getElementById('video');
      const parent = document.getElementById(options.parent!);

      if (!video) {
        const videoElement = document.createElement('video');
        videoElement.id = 'video';
        videoElement.setAttribute('class', options.className || '');
        videoElement.setAttribute(
          'style',
          '-webkit-transform: scaleX(-1); transform: scaleX(-1);',
        );

        parent!.appendChild(videoElement);

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          // Not adding `{ audio: true }` since we only want video now
          navigator.mediaDevices.getUserMedia({ video: true }).then(
            stream => {
              // video.src = window.URL.createObjectURL(stream);
              videoElement.srcObject = stream;
              videoElement.play();
              resolve({});
            },
            err => {
              reject(err);
            },
          );
        }
      } else {
        reject({ message: '[capacitor-elinkx-machine-learning]: camera already started' });
      }
    });
  }

  async stop(): Promise<any> {
    const video = <HTMLVideoElement>document.getElementById('video');
    if (video) {
      video.pause();

      const st: any = video.srcObject;
      const tracks = st.getTracks();

      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i];
        track.stop();
      }
      video.remove();
    }
  }

  async capture(_options: CameraPreviewPictureOptions): Promise<any> {
    return new Promise((resolve, _) => {
      const video = <HTMLVideoElement>document.getElementById('video');
      const canvas = document.createElement('canvas');

      // video.width = video.offsetWidth;

      const context = canvas.getContext('2d');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context!.translate(video.videoWidth, 0);
      context!.scale(-1, 1);
      context!.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      resolve({
        value: canvas
          .toDataURL('image/png')
          .replace('data:image/png;base64,', ''),
      });
    });
  }

  async getSupportedFlashModes(): Promise<{
    result: CameraPreviewFlashMode[];
  }> {
    throw new Error(
      '[capacitor-elinkx-machine-learning]: getSupportedFlashModes not supported under the web platform',
    );
  }

  async setFlashMode(_options: {
    flashMode: CameraPreviewFlashMode | string;
  }): Promise<void> {
    throw new Error('[capacitor-elinkx-machine-learning]: setFlashMode not supported under the web platform');
  }

  async flip(): Promise<void> {
    throw new Error('[capacitor-elinkx-machine-learning]: flip not supported under the web platform');
  }

  async echo(_options: { value: string }): Promise<{ value: string }> {
    throw new Error('[capacitor-elinkx-machine-learning]: echo not supported under the web platform');
  }

  async isCameraActive(): Promise<{ value: boolean }> {
    throw new Error('[capacitor-elinkx-machine-learning]: isCameraActive not supported under the web platform');
  }

  async scan(_options: ScanOptions): Promise<{}> {
    throw new Error('[capacitor-elinkx-machine-learning]: scan not supported under the web platform');
  }
}

const MachineLearning = registerPlugin<MachineLearningWeb>('MachineLearning');

export { MachineLearning };
