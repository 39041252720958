/* eslint-disable import/no-duplicates */
/* eslint-disable import/prefer-default-export */

import "capacitor-elinkx-network"; // je nutno importovat takto kvůli capacitoru
import { ElinkxNetwork } from "capacitor-elinkx-network"; // je nutno importovat takto kvůli capacitoru

import { NetworkStatus, NetworkStrength } from "@elx-element/common/enums";
import { getConfigurationStringValue } from "@elx-element/common/envconf";

import { Middleware, PayloadAction } from "@reduxjs/toolkit";

import { setNetworkStatus } from "./main/slice";

export const DetectNetworkStatusMiddleware = (_ => next => {
  let setFromExternal = false;
  ElinkxNetwork.getStatus().then(item => {
    if (item.networkType === "browser") {
      const abort = new AbortController();
      setTimeout(() => {
        abort.abort();
      }, 5000);
      const testUrl = new URL(getConfigurationStringValue(window.env.webcontainer, "AUTH_URL"));
      testUrl.searchParams.append("s", `${new Date().getTime()}`);
      fetch(`${testUrl}`, {
        signal: abort.signal,
      })
        .then(data => data.json())
        .then(data => data.public_key)
        .then(hasData => {
          if (setFromExternal) return;
          if (hasData) {
            next(setNetworkStatus(NetworkStatus.online));
          }
        })
        .catch(() => {
          if (setFromExternal) return;
          next(setNetworkStatus(NetworkStatus.offline));
        });
    }
    next(
      setNetworkStatus(item.networkStrength !== NetworkStrength.none ? NetworkStatus.online : NetworkStatus.offline)
    );
  });

  ElinkxNetwork.addListener("networkStatusChange", status => {
    setFromExternal = true;
    next(
      setNetworkStatus(status.networkStrength !== NetworkStrength.none ? NetworkStatus.online : NetworkStatus.offline)
    );
  });

  return (action: PayloadAction<NetworkStatus>) => {
    setFromExternal = setFromExternal || action.type === "main/setNetworkStatus";
    next(action);
  };
}) as Middleware;
