import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  dialogMenuVersionRoot: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderTop: "1px dotted",
    borderColor: theme.palette.divider,
    display: "block",
  },
  versionTable: {
    fontSize: "small",
    display: "block",
    marginBottom: theme.spacing(1),
    "& .row": { display: "table-row" },
    "& .cell": { display: "table-cell", paddingRight: theme.spacing(1) },
  },
}));
export default useStyles;
