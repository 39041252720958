/* eslint-disable import/prefer-default-export */

import { useCallback, useEffect, useState } from "react";

import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";

import { NetworkStatus, Notification } from "@elx-element/common/enums";
import { dispatchNotificationEvent } from "@elx-element/common/events/dispatchers";
import { NotificationEventModel } from "@elx-element/common/events/types";

import { selectActiveModule, selectNetworkStatus, selectRoutes } from "../store/main/selectors";

import useTexts from "./useTexts";

import userManager from "../auth/userManager";
import History from "../history";

/**
 * Hook lze volat pouze jednou při startu aplikace.
 */
export const useNetworkConnection = () => {
  const auth = useAuth();
  const networkStatus = useSelector(selectNetworkStatus);
  const routes = useSelector(selectRoutes);
  const [alerted, setAlerted] = useState(false);
  const texts = useTexts();
  const activeModule = useSelector(selectActiveModule); // reference na otevřený modul

  /**
   * Funkce pro zobrazení notifikace 5 minut před koncem platnosti tokenu.
   */
  const createNotificationTokenExpiring = useCallback(async () => {
    dispatchNotificationEvent(
      new NotificationEventModel(
        texts.ACCESS_TOKEN_IS_EXPIRING,
        Notification.warning,
        undefined,
        undefined,
        undefined,
        true
      )
    );
  }, [auth.user]);

  useEffect(() => {
    if (!auth.isAuthenticated) return;

    if (networkStatus === NetworkStatus.offline && auth.user) {
      // dočasné pozastavení obnovení tokenu
      userManager.stopSilentRenew();
      if (alerted) return;
      let timeoutAlert = auth.user.expires_at! - new Date().getTime() / 1000;

      if (timeoutAlert <= 0) {
        return;
      }
      if (timeoutAlert < 500) {
        timeoutAlert = 3;
      }
      const refTimeoutAlert = setTimeout(() => {
        setAlerted(true);
        createNotificationTokenExpiring();
      }, timeoutAlert * 1000);

      // výjmutí hlášky, zablokováno z důvodu, že useEffect umožnujě jak undefined, tak destruct funkci
      // eslint-disable-next-line consistent-return
      return () => {
        clearTimeout(refTimeoutAlert);
      };
    }

    setAlerted(false);
    /**
     * nutno řešit takto (ne redux), protože App se je nahozeno dříve, než login/logout/callback
     * spravně by mělo být router -> (callback|login|logout|stranka, ktera primo nepotrebuje uzivatele|loggeduser -> App s přihlášeným či pin uživatelem)
     */
    const noPrivates = new RegExp(
      `^/(${routes
        .filter(item => !item.private)
        .map(item => item.route)
        .join("|")})`
    );

    if (!`${document.location.pathname}`.match(noPrivates)) {
      if (!auth.user) {
        userManager.signinRedirect();
      } else if (!auth.user.expired) {
        userManager.startSilentRenew();
      }
    }
  }, [networkStatus, createNotificationTokenExpiring]);

  /**
   * Logika pro zabránění změny routy při výpadku internetového spojení.
   * Lze přeroutovat pouze v rámci aktuálně otevřeného modulu.
   */
  useEffect(() => {
    let isValid = true;
    const unblock = History.block(({ pathname }) => {
      if (
        networkStatus === NetworkStatus.offline &&
        (!activeModule || !pathname.startsWith(`/${activeModule.moduleId}`))
      ) {
        isValid = false;
      }
      // if is valid we can allow the navigation
      if (isValid) {
        // we can now unblock
        unblock();
        // proceed with the blocked navigation
        History.push(pathname);
      }
      // prevent navigation
      return false;
    });
    return unblock;
  }, [networkStatus]);
};
