/* eslint-disable no-param-reassign */
import { storeUsers } from "@elx-element/common/storage";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ApplicationEventModel,
  MessageModel,
  NotificationModel,
  NotificationSubcriptionModel,
  UserModel,
} from "../../types.generated";

export interface messageState {
  notifications: Array<NotificationModel>;
  messages: Array<MessageModel>;
  users: Array<UserModel>;
  applicationEvents: Array<ApplicationEventModel>;
  subcriptions: Array<NotificationSubcriptionModel>;
}

const initialState: messageState = {
  notifications: [],
  messages: [],
  users: [],
  applicationEvents: [],
  subcriptions: [],
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    // Akce pro uložení příchozích zpráv do storu
    addMessages: (state, action: PayloadAction<Array<MessageModel>>) => {
      state.messages = action.payload;
    },
    // Akce pro uložení jedné příchozí zprávy do storu
    addMessage: (state, action: PayloadAction<MessageModel>) => {
      state.messages = [...state.messages, action.payload];
    },
    // Akce pro odstranění vybrané zprávy ze storu
    removeMessage: (state, action: PayloadAction<number>) => {
      state.messages = state.messages.filter(x => x.id !== action.payload);
    },
    // Akce pro uložení příchozích notifikací do storu
    addNotifications: (state, action: PayloadAction<Array<NotificationModel>>) => {
      state.notifications = action.payload;
    },
    // Akce pro uložení jedné příchozí notifikace do storu
    addNotification: (state, action: PayloadAction<NotificationModel>) => {
      state.notifications = [...state.notifications, action.payload];
    },
    // Akce pro odstranění vybrané notifikace ze storu podle notificationId
    removeNotification: (state, action: PayloadAction<number>) => {
      state.notifications = state.notifications.filter(x => x.id !== action.payload);
    },
    // Akce pro odstranění vybrané notifikace ze storu podle notificationCode
    removeNotificationByCode: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter(x => x.code !== action.payload);
    },
    // Akce pro uložení seznamu uživatelů do storu
    addUsers: (state, action: PayloadAction<Array<UserModel>>) => {
      storeUsers(action.payload);
      state.users = action.payload;
    },
    // Akce pro uložení seznamu typů notifikací
    addApplicationEvents: (state, action: PayloadAction<Array<ApplicationEventModel>>) => {
      state.applicationEvents = action.payload;
    },
    // Akce pro uložení seznamu odebíraných notifikací
    addSubcriptions: (state, action: PayloadAction<Array<NotificationSubcriptionModel>>) => {
      state.subcriptions = action.payload;
    },
  },
});

export const {
  addMessages,
  addMessage,
  removeMessage,
  addNotifications,
  addNotification,
  removeNotification,
  removeNotificationByCode,
  addUsers,
  addApplicationEvents,
  addSubcriptions,
} = messageSlice.actions;
export default messageSlice;
