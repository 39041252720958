import type { PluginListenerHandle } from '@capacitor/core';

export interface ElinkxNetworkPlugin {
  getStatus(): Promise<NetworkStatus>;

  addListener(
    eventName: 'networkStatusChange',
    listenerFunc: NetworkStatusChangeListener,
  ): Promise<PluginListenerHandle>;

  removeAllListeners(): Promise<void>;
}

export interface NetworkStatus {
  networkType: NetworkType;
  networkStrength: NetworkStrength;
}

export type NetworkStatusChangeListener = (status: NetworkStatus) => void;

export enum NetworkType {
  browser = 'browser',
  wifi = 'wifi',
  cellular = 'cellular',
  none = 'none',
}

export enum NetworkStrength {
  none = 'none',
  poor = 'poor',
  good = 'good',
}
