import Lang from "./lang";

const plPL: Lang = {
  DASHBOARD: "Pulpit nawigacyjny",
  COPYRIGHT_TEXT: "E LINKX a.s.",
  COPYRIGHT_LINK: "https://www.elinkx.cz",
  ACTIVE_MODULES: "Zainstalowane moduły",
  VERSION: "Wersja",
  BUILD: "Wersja przedpremierowa",
  SETTINGS: "Ustawienia",
  LOGOUT: "Wyloguj się",
  INCOMPATIBILITY: "Ten moduł może być niekompatybilny.",
  INCOMPATIBILITY_NOTIFICATION: "Ostrzeżenie! Niektóre moduły mogą być niekompatybilne.",
  UNSUPPORTED_COMMUNICATION_TYPE: "Nieobsługiwany typ komunikacji.",
  UNSUPPORTED_ACTION_DATA: "Błąd danych działania.",
  DATETIME: "Data i godzina",
  TYPE: "Typ",
  SPECIFICATION: "Specyfikacja",
  RANK: "Zamówienie",
  BACK_TO_DASHBOARD: "Powrót do PULPITU NAWIGACYJNEGO",
  NOTIFICATION: "Powiadomienia",
  MESSAGES: "Wiadomości",
  YOU_HAVE_MESSAGES: "Masz nowe wiadomości",
  YOU_HAVE_NOTIFICATIONS: "Masz nowe powiadomienia",
  MESSAGE_FROM_USER: "{0}: {1}",
  SHOW: "Pokaż",
  OPEN_ACTION: "Otwórz link",
  CLOSE: "Zamknij",
  SYSTEM_NOTIFICATION: "Powiadomienie systemowe",
  TEXT_MESSAGE: "Wiadomość tekstowa",
  DELETE: "Usuń",
  RESOLVE: "Rozwiąż",
  NO_DATA: "Nie ma nic do pokazania.",
  EXECUTE_ACTION_FAILED: "Wykonanie działania nie powiodło się.",
  MESSAGE_SEND: "Wiadomość została pomyślnie wysłana.",
  ERROR: "Błąd",
  ERROR_CODE: "Kod błędu",
  ERROR_MESSAGE: "Wystąpił błąd w aplikacji.",
  ERROR_OCCURED: "Wystąpił błąd w module '{0}'.",
  ERROR_REPORT: "Zgłoś błąd",
  ERROR_REPORTED: "Zgłoszono błąd",
  ERROR_ALREADY_REPORTED: "Błąd został już zgłoszony",
  ERROR_UNKNOWN: "Nieznany błąd",
  ERROR_TRY_IT_LATER: "Żądana czynność nie może być teraz wykonana. Prosimy spróbować ponownie później.",
  ERROR_UNKNOWN_OCCURED:
    "The requested action cannot be performed at this time. Please try again later or create a JIRA record.",
  ERROR_SPECIFIC_OCCURED:
    "There is a problem in the application that can be solved. Click on the 'Help' button to find out more about the problem and how to solve it. If the problem persists, create a JIRA record.",
  REPORT_TO_JIRA: "Zgłoś się do JIRA",
  JIRA_SPECIFICATION: "Opisz błąd. Twój komentarz sprawi, że usunięcie wady będzie łatwiejsze i szybsze.",
  KNOWLEDGE_BASE: "Baza wiedzy",
  DETAIL: "Szczegół",
  CANCEL: "Anuluj",
  CREATE: "Utwórz",
  USERNAME: "Nazwa użytkownika",
  EMAIL: "Adres e-mail",
  REALM_ACCESS: "Role",
  COPY_TOKEN: "Kopiuj token",
  COPIED: "Skopiowano",
  PROFILE: "Profil",
  DELETE_NOTIFICATION: "Usuń powiadomienie",
  STOP_SHOW: "Ukryj te powiadomienia",
  UNSUBSCRIBE: "Anuluj subskrypcję",
  UNSUBSCRIBE_SUCCESS: "wylogowano z subskrypcji '{0}'.",
  SUBSCRIBE_SUCCESS: "Zalogowano do subskrypcji '{0}'.",
  SUBSCRIBE_SETTINGS: "Ustawienia subskrypcji",
  LOGIN_SERVER_UNREACHABLE: "Serwer logowania jest niedostępny.",
  LOGIN_ERROR: "Wystąpił błąd podczas logowania. ",
  SEND_COMMENT: "Wyślij komentarz",
  COMMENT: "Komentarz",
  COMMENT_SENT: "Komentarz został wysłany",
  SEND: "Wyślij",
  FEEDBACK: "Informacje zwrotne",
  SCANNING: "Skanowanie",
  WRITING: "Pisanie",
  USE: "Zastosowanie",
  SCAN_AGAIN: "Skanuj ponownie",
  TAKING_PICTURE: "Aparat fotograficzny",
  TAKE_PICTURE: "Przechwyć",
  TRY_AGAIN: "Spróbuj ponownie",
  TRY_IT_LATER: "Spróbuj ponownie później.",
  LOGOUT_ERROR: "Wystąpił błąd podczas wylogowywania.",
  NFC_INITIALIZING: "Inicjowanie...",
  NFC_NO_NFC: "Wygląda na to, że używane urządzenie nie ma NFC lub aplikacja nie została poprawnie skonfigurowana.",
  NFC_NOT_ENABLED:
    "NFC is not enabled on your device. Click the button bellow to open your device's settings, then activate NFC.",
  NFC_WAITING_FOR_ENABLED: "Po włączeniu NFC kliknij poniższy przycisk.",
  NFC_WAITING_FOR_TAG: "Przesuń urządzenie w pobliże tagu NFC.",
  NFC_NO_DATA: "Tag NFC jest pusty.",
  NFC_GO_TO_NFC: "Przejdź do ustawień NFC",
  NFC_INIT_NFC: "Zainicjuj NFC",
  NFC_DATA_WRITE_SUCCESS: "Transmisja NFC powiodła się.",
  NFC_DATA_WRITE_ERROR: "Transmisja NFC nie powiodła się.",
  NFC_ERROR: "Wystąpił błąd podczas próby otwarcia ustawień NFC.",
  NFC_LOCK_SUCCESS: "Znacznik NFC został trwale zablokowany.",
  NFC_LOCK_FAILURE: "Trwała blokada tagu NFC nie powiodła się.",
  NFC_LOCK_WARNING: "Ostrzeżenie: jest to trwałe i nie można tego cofnąć.",
  NFC_PERMANENT_LOCK_TAG: "Blokada stała",
  TILE: "Kafelek",
  TILE_SETTINGS: "Ustawienia wyświetlania kafelków",
  TILE_REARRANGE: "Tryb przesuwania kafelków i aktywacja widżetu",
  TILE_REARRANGE_INFO: "W tym trybie linki do kafelków i widżetów są dezaktywowane.",
  TILE_UNCOMPATIBILITY: "Pokaż alerty o brakujących komponentach",
  TILE_UNCOMPATIBILITY_INFO: "Na kafelkach pojawi się ikona informacyjna opisująca niezgodność.",
  ENVIROMENT: "Środowisko",
  BACK: "Wstecz",
  MODULE: "Moduł",
  MODULE_INCOPATIBILITY_DETECTED: "Wykryto niezgodność z modułem '{0}'.",
  MODULE_INCOPATIBLE_EVENT: "Niepotwierdzone zdarzenie '{0}' w module '{1}'.",
  CONFIG_FILE_MISSING: "Brak pliku konfiguracyjnego w module '{0}'.",
  NETWORK_STATUS_OFFLINE: "Połączenie sieciowe zostało utracone!",
  NETWORK_STATUS_ONLINE: "Połączenie sieciowe zostało przywrócone.",
  ACCESS_TOKEN_IS_EXPIRING:
    "The session will expire in 5 minutes. The application requires a network connection to continue.",
  LICENSE_PROBLEM_TITLE: "Problem licencyjny",
  LICENSE_EXPIRING: "Licencja na moduł wkrótce wygaśnie. {0}.",
  LICENSE_EXPIRED: "Licencja na moduł wygasła.",
  LICENSE_NOTIFICATION: "Zbliża się termin wygaśnięcia licencji modułu: {0}",
  LICENSE_ERROR_NOTIFICATION: "Licencja modułu wygasła: {0}",
  PIN: "Logowanie offline za pomocą numeru PIN",
  PIN_BIOMETRICS: "Logowanie offline z autoryzacją",
  PIN_BIOMETRICS_INFO:
    "Aby skorzystać z aplikacji bez dostępu do Internetu, konieczna jest autoryzacja. Alternatywnie można użyć formularza logowania online, ale wymaga to połączenia z Internetem.",
  PIN_BIOMETRICS_USE: "Logowanie z autoryzacją",
  PIN_INFO:
    "Aby skorzystać z aplikacji bez dostępu do Internetu (offline), należy wpisać kod PIN (osobisty numer identyfikacyjny). Alternatywnie można użyć formularza logowania online, ale wymaga to połączenia z Internetem.",
  PIN_USE: "Logowanie za pomocą numeru PIN",
  PIN_BIOMETRICS_TITLEDIALOG: "Autoryzacja",
  PIN_BIOMETRICS_DESCDIALOG: "Autoryzuje...",
  KEYCLOAK_USE: "Logowanie za pomocą formularza online",
  PIN_ERROR: "Wprowadzony kod PIN jest nieprawidłowy",
  SW_LOADING: "Przygotowywanie aplikacji (pracownik serwisu)",
  MODULE_UPDATE_AVAILABLE: "Aktualizacja modułu jest dostępna",
  WEBCONTAINER_UPDATE_AVAILABLE: "Aktualizacja web container jest dostępna",
  MODULE_MISSING_DEPENDENCY: "Moduł {0} zawiera zależność z modułu {1}",
  ACCESS_TOKEN_IS_EXPIRING_AVAIL:
    "Sesja wygasa za 5 minut. Aby przedłużyć sesję online, wymagane jest połączenie sieciowe",
  LOGIN_REQUESTED: "Zaloguj się, jeśli to możliwe.",
  WEBCONTAINER_SETTINGS: "Ustawienia użytkownika aplikacji.",
  LOCAL_SETTINGS: "Ustawienia lokalne",
  MACHINE_NAME: "Nazwa stacji",
  MACHINE_PLACEHOLDER: "Wprowadź nazwę dla tego urządzenia.",
  SERVICEWORKER_ACTIVE: "Pracownik serwisu aktywny w trybie debugowania",
  WHISPERER_ISNOT_ACTIVE: "Whisperer for module '{0}' is not active.",
  ERROR_HELP_IS_MISSING: "Nie można załadować pomocy dotyczącej błędu.",
  ABOUT_US: "O nas",
  CONTACTS: "Kontakty",
  BRANCH: "Oddział",
  FAQ: "FAQ",
  INFORMATION: "Informacja",
  BUSINESS_DEPARTMENT: "Dział biznesowy",
};
export default plPL;
