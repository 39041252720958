import { useSelector } from "react-redux";

import { selectCulture } from "../store/main/selectors";

import csCZ from "../languages/csCZ";
import enUS from "../languages/enUS";
import huHU from "../languages/huHU";
import Lang from "../languages/lang";
import plPL from "../languages/plPL";

/**
 * Hook pro přístup ke slovníku dle nastaveného aktuálního jazyka prostředí.
 */
const useTexts = () => {
  const culture = useSelector(selectCulture);

  const getDictionary = (): Lang => {
    switch (culture) {
      case "cs-CZ":
        return csCZ;
      case "en-US":
        return enUS;
      case "hu-HU":
        return huHU;
      case "pl-PL":
        return plPL;
      default:
        return csCZ;
    }
  };

  return getDictionary();
};

export default useTexts;
