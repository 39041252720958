import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  root: {
    top: "55px!important;",
    maxHeight: `calc(100% - 65px)`,
    overflowY: "hidden",
  },
  container: {
    width: 480,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  header: {
    padding: theme.spacing(1),
  },
  message: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      "& .moreVertIcon": {
        display: "block",
        background: "none",
      },
    },
  },
  messageText: {
    width: "calc(100% - 60px)",
    display: "block",
    marginLeft: theme.spacing(4),
  },
  notificationIcon: {
    marginRight: theme.spacing(1),
    float: "left",
    cursor: "help",
  },
  messages: {
    overflowY: "auto",
    maxHeight: 480,
    clear: "both",
  },
  subSettingsButton: {
    float: "right",
    cursor: "pointer",
    display: "none",
  },
  settingsButton: {
    float: "right",
    margin: theme.spacing(1),
  },
  closeButton: {
    float: "right",
    padding: 0,
    marginTop: theme.spacing(-0.5),
  },
  submenuIcon: {
    marginRight: theme.spacing(1.5),
  },
}));
export default useStyles;
