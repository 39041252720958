import React from "react";

interface Props {
  // Identifikátor modulu
  moduleId: string;
  // Identifikátor widgetu
  widgetRoute: string;
  // Absolutní cesta ke zdrojovým souborům modulu. Pokud je zadáná, stahují se moduly z externího serveru.
  // V opačném případě se načítají z lokálního adresáře public/widgets (pouze z důvodu vývoje.)
  widgetPath?: string;
}

/**
 * Komponenta externího modulu.
 * Provede embedování kompletní aplikace do stránky pomocí iframe.
 * @param props
 */
const WidgetLoader: React.FC<Props> = (props: Props) => 
  // Render placeholderu pro widget.
   (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <iframe
      src={`${props.widgetPath ?? "" }/modules/${ props.moduleId}/widgets/${props.widgetRoute}/index.html?v=${Date.now()}`}
      width="100%"
      height="100%"
      frameBorder="0"
    />
  )
;

export default WidgetLoader;
