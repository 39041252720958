import { useEffect, useState } from "react";

import { batch, useSelector } from "react-redux";

import { MenuItem } from "@mui/material";

import { WebContainerPlatform } from "@elx-element/common/enums";

import { App, AppInfo } from "@capacitor/app";

import { selectActiveModule, selectPlatform } from "../../store/main/selectors";

import useTexts from "../../hooks/useTexts";

import useStyles from "./styles";

const VersionPopover = () => {
  const texts = useTexts();
  const openApp = useSelector(selectActiveModule);
  const { classes } = useStyles();
  const platform = useSelector(selectPlatform);
  const [elementWrapperInfo, setElementWrapperInfo] = useState<AppInfo | undefined>();

  useEffect(() => {
    batch(async () => {
      if (platform !== WebContainerPlatform.web) {
        setAppInfo();
      }
    });
  }, [platform]);

  const setAppInfo = async () => {
    await App.getInfo().then(x => {
      setElementWrapperInfo(x);
    });
  };

  const getEnviromentFromHostname = () => {
    const hostName = window.location.hostname.toLowerCase();
    if (hostName === "localhost") {
      return "localhost";
    }
    if (hostName.includes("-rc.")) {
      return "rc";
    }
    if (hostName.includes("-dev.")) {
      return "dev";
    }
    if (hostName.includes("-test.")) {
      return "test";
    }
    return "rtm";
  };

  const webcontainerBuild = (document.querySelector("meta[name='webcontainer-build']") as HTMLMetaElement).content;
  const webcontainerVersion = (document.querySelector("meta[name='webcontainer-version']") as HTMLMetaElement).content;
  const moduleBuild = (document.querySelector("meta[name='module-build']") as HTMLMetaElement).content;
  const moduleVersion = (document.querySelector("meta[name='module-version']") as HTMLMetaElement).content;

  const getClipboardText = () => {
    let text = `webcontainer: ${texts.BUILD.toLowerCase()} ${
      webcontainerBuild || "0.0.0.0"
    }; ${texts.VERSION.toLowerCase()} ${webcontainerVersion || "0.0.0"}; `;
    if (openApp) {
      text += `${openApp.moduleId}: ${texts.BUILD.toLowerCase()} ${
        moduleBuild || "0.0.0.0"
      }; ${texts.VERSION.toLowerCase()} ${moduleVersion || "0.0.0"}; `;
    }
    text += `${texts.ENVIROMENT.toLowerCase()}: ${getEnviromentFromHostname()}`;
    return text;
  };

  const copyToClipboard = () => {
    if (platform === WebContainerPlatform.web) {
      navigator.clipboard.writeText(getClipboardText());
    }
  };

  return (
    <MenuItem onClick={copyToClipboard} className={classes.dialogMenuVersionRoot}>
      <div className={classes.versionTable}>
        <b>{process.env.REACT_APP_NAME}</b>
        <div className="row">
          <div className="cell">{texts.BUILD.toLowerCase()}:</div>
          <div className="cell">{webcontainerBuild || "0.0.0.0"}</div>
        </div>
        <div className="row">
          <div className="cell">{texts.VERSION.toLowerCase()}:</div>
          <div className="cell">{webcontainerVersion || "0.0.0"}</div>
        </div>
      </div>
      {openApp && (
        <div className={classes.versionTable}>
          <b>{openApp.moduleId}</b>
          <div className="row">
            <div className="cell">{texts.BUILD.toLowerCase()}:</div>
            <div className="cell">{moduleBuild || "0.0.0.0"}</div>
          </div>
          <div className="row">
            <div className="cell">{texts.VERSION.toLowerCase()}:</div>
            <div className="cell">{moduleVersion || "0.0.0"}</div>
          </div>
        </div>
      )}
      <div className={classes.versionTable}>
        <div className="row">
          <div className="cell">{texts.ENVIROMENT.toLowerCase()}:</div>
          <div className="cell">{getEnviromentFromHostname()}</div>
        </div>
      </div>
      {elementWrapperInfo && (
        <div className={classes.versionTable}>
          <b>wrapper</b>
          <div className="row">
            <div className="cell">{elementWrapperInfo.name}:</div>
            <div className="cell">{elementWrapperInfo.version}</div>
          </div>
        </div>
      )}
    </MenuItem>
  );
};

export default VersionPopover;
