import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  appBar: {
    position: "relative",
    border: "none",
  },
  title: {
    flex: 1,
    color: theme.palette.primary.contrastText,
    userSelect: "none",
  },
  scannerDialogRoot: {
    "& div": {
      backgroundColor: "transparent",
    },
  },
  scannerDialogPaper: {
    backgroundColor: "transparent",
    border: "none",
  },
  scannerDialogContainer: {
    display: "block!important",
  },
  scanButtonContainer: {
    position: "absolute",
    bottom: 25,
    right: theme.spacing(2),
    width: "50%",
    maxWidth: 225,
  },
  scanButton: {
    width: "100%",
    height: "50px",
    display: "block",
    marginBottom: theme.spacing(2),
  },
  scanButtonAgain: {
    backgroundColor: theme.palette.background.paper,
  },
  scanButtonCentered: {
    float: "none",
    margin: "0 auto",
  },
  scannedValue: {
    margin: "25px auto",
    fontSize: 25,
    color: "#fff",
    textShadow: "1px 1px black",
  },
  toolbar: {
    minHeight: 50,
  },
  nfcInfo: {
    margin: "50% auto",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  nfcLockWarning: {
    padding: theme.spacing(3),
    fontWeight: "bold",
    textAlign: "center",
  },
  nfcDialogPaper: {
    border: "none",
  },
}));
export default useStyles;
