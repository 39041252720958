import { makeStyles, withStyles } from "tss-react/mui";

import TreeItem from "@mui/lab/TreeItem";

const useStyles = makeStyles()(theme => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  treelabel: {
    marginRight: 10,
    color: theme.palette.secondary.dark,
    fontWeight: 800,
    fontSize: "small",
  },
  treeSelectedItem: {
    backgroundColor: "inherit",
  },
  hiddenNodeLabel: {
    marginLeft: theme.spacing(0.5),
    display: "block",
    padding: 10,
    color: "#1d1d1d",
    textTransform: "uppercase",
    fontSize: "small",
  },
  rootLabel: {
    marginLeft: theme.spacing(2),
  },
  hiddenNode: {
    display: "none",
  },
  tableContainer: {
    maxWidth: 600,
    margin: 0,
    padding: 0,
    marginBottom: theme.spacing(4),
    "& table": {
      fontSize: "small",
    },
  },
  headding: {
    fontWeight: 100,
  },
}));

export const StyledTreeItem = withStyles(TreeItem, {
  content: {
    paddingLeft: 0,
    height: 40,
  },
});

export default useStyles;
