import React, { useEffect } from "react";

import { useSnackbar } from "notistack";
import { useAuth } from "react-oidc-context";
import { useLocation } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";

import { getConfigurationNumberValue } from "@elx-element/common/envconf";

import useAppStyles from "../app/styles";

import TryLaterPage from "./TryLaterPage";

import History from "../../history";

const CallbackPage = () => {
  const { classes } = useAppStyles();
  const snackbar = useSnackbar();
  const location = useLocation();
  const auth = useAuth();

  // Timeout komponenty. Pokud loading probíhá déle než stanovaný čas, zobrazí se chyba.
  const [timeoutOccured, setTimeoutOccured] = React.useState<boolean>(false);
  let timer: any;
  const timeout = getConfigurationNumberValue(window.env.webcontainer, "KEYCLOAK_CALLBACK_TIMEOUT");

  const handleCallbackTimeout = () => {
    setTimeoutOccured(true);
    // Vymaže zásobník chybových notifikací - na chybové stránce nechceme zobrazovat chybové notifikace.
    snackbar.closeSnackbar();
  };

  useEffect(() => {
    timer = setTimeout(handleCallbackTimeout, timeout);
    return () => {
      clearTimeout(timer);
    };
  }, [auth]);

  const processCallback = () => {
    if (auth.isAuthenticated) {
      const url = sessionStorage.getItem("urlBeforeRedirect");
      sessionStorage.removeItem("urlBeforeRedirect");
      if (!url) {
        History.push("/dashboard");
      } else {
        History.push(`${url}`);
      }
    }
  };

  useEffect(() => {
    processCallback();
  }, [auth, location]);

  return (
    <div className={classes.centeredContent}>
      {!timeoutOccured && <CircularProgress />}
      {timeoutOccured && <TryLaterPage />}
    </div>
  );
};

export default CallbackPage;
