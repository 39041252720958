import { combineReducers } from "redux";

import { configureStore } from "@reduxjs/toolkit";

import mainSlice from "./main/slice";
import messageSlice from "./message/slice";
import { DetectNetworkStatusMiddleware } from "./middleware";

const rootReducer = combineReducers({
  main: mainSlice.reducer,
  message: messageSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ immutableCheck: true, serializableCheck: false, thunk: false }).concat([
      DetectNetworkStatusMiddleware,
    ]),
});

export type AppState = ReturnType<typeof store.getState>;
export default store;
