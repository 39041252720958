import React, { useEffect } from "react";

import { User } from "oidc-client-ts";
import { useAuth } from "react-oidc-context";

import CircularProgress from "@mui/material/CircularProgress";

import { getConfigurationBoolValue, getConfigurationNumberValue } from "@elx-element/common/envconf";

import useAppStyles from "../app/styles";

import userManager from "../../auth/userManager";

const debug = getConfigurationBoolValue(window.env.webcontainer, "ENABLE_DEBUG_LOG");

const LogoutPage = () => {
  const { classes } = useAppStyles();
  const auth = useAuth();

  // Timeout komponenty. Pokud loading probíhá déle než stanovaný čas, zobrazí se chyba.
  const [timeoutOccured, setTimeoutOccured] = React.useState<boolean>(false);
  let timer: any;
  const timeout = getConfigurationNumberValue(window.env.webcontainer, "KEYCLOAK_CALLBACK_TIMEOUT");

  /** onLogout
   * @param platform
   */
  const onLogout = async (user: User) => {
    userManager
      .signoutRedirect({
        // eslint-disable-next-line camelcase
        id_token_hint: user.id_token,
      })
      .catch(error => {
        if (debug) {
          console.debug(`[Element]: Signout popup error:${error}`);
        }
      });
  };

  useEffect(() => {
    if (auth.user) {
      timer = setTimeout(() => setTimeoutOccured(true), timeout);
      onLogout(auth.user);
    }
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (timeoutOccured) {
      window.location.href = `${window.location.origin}/login`;
    }
  }, [timeoutOccured]);

  return <div className={classes.centeredContent}>{!timeoutOccured && <CircularProgress />}</div>;
};

export default LogoutPage;
